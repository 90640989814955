import { AppDispatch } from "../../Redux/store";
import {
  ChangeWallORTileI,
  RCommonStateI,
  RConfigStateI,
  SphereDataI,
} from "../../Types";
import {
  change_modal,
  clear_result_checker,
  clear_result_data,
  set_current_process_type,
  set_new_spheres_status,
  set_old_spheres_status,
} from "../../Redux/Actions";
import { Sphere_controller } from "../../Controllers";
import { clear_still_photo_base_64 } from "../../Redux/Actions/CommonActions/assetsAction";

export const choose_hard_coded_sphere = (
  selected_sphere_id: number,
  dispatch: AppDispatch,
  common: RCommonStateI,
  config: RConfigStateI,
  sphere: SphereDataI,
  change_wall_or_tile_new: (payload: ChangeWallORTileI) => void
): void => {
  if (selected_sphere_id !== sphere.id) {
    dispatch(set_old_spheres_status());
  }
  dispatch(change_modal());
  dispatch(clear_result_data());
  dispatch(clear_result_checker());
  dispatch(set_new_spheres_status());
  dispatch(clear_still_photo_base_64());
  dispatch(set_current_process_type("hardcoded sphere"));
  change_wall_or_tile_new({
    wall: common.look?.wallColor,
    tile: common.look?.floorTile,
    sphere_endpoint: sphere.name,
    process_type: "hardcodedsphere",
    interactionSource: config.look_uploaded ? "customLook" : "hardcodedLook",
    common,
    config,
  });

  Sphere_controller.handler_sphere(
    sphere,
    dispatch,
    common.look,
    config.look_uploaded
  );
};
