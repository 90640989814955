export const vendor_name_cut_number = 30,
  material_name_cut_number = 15;
export const handler_marker_text = (
  cut_number: number,
  marker_text: string
): string => {
  let new_text = "";
  if (marker_text?.length > cut_number) {
    if (marker_text[cut_number] !== " ") {
      new_text = `${marker_text.slice(0, cut_number)}...`;
    }
    if (marker_text[cut_number] === " ") {
      new_text = `${marker_text.slice(0, cut_number - 1)}...`;
    }
    return new_text;
  }
  return marker_text;
};
