import { Dispatch, SetStateAction } from "react";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store";
import { CookieSetOptions } from "universal-cookie";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  change_showed_sphere,
  is_clicked,
  set_count,
  set_user,
  text_thanks,
} from "../CommonActions/configAction";
import { fetchingDataWithAxiosMiddleware } from "./commonAction";
import { ServiceApi, ServiceDataControl } from "../../../Services";
import {
  set_loading_time,
  set_result_checker,
} from "../CommonActions/commonAction";
import {
  ChangeWallORTileI,
  FloorsColorRecordT,
  TileTypeDataI,
  UserI,
  WallColorDataI,
  WallsColorRecordT,
} from "../../../Types";
import {
  get_next_random_tiles,
  get_next_random_walls,
  select_tile,
  select_wall,
  set_random_tiles,
  set_random_walls,
} from "../CommonActions/wallAndTileAction";
import { set_new_still_photo_url } from "../CommonActions/stillPhotoAction";

export interface LoadingTimeResponseI {
  ID: number;
  minutesRemaining: number;
  secondsRemaining: number;
}

const expires_on = new Date();
expires_on.setDate(new Date().getDate() + 2);

export const get_random_walls_tiles =
  (
    page: number,
    count: number,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    material_type: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      setIsLoading(true);
      const response = await fetchingDataWithAxiosMiddleware(
        "GET",
        `${ServiceApi.get_random_walls_tiles_url(page, count, material_type)}`
      );

      if (response?.status) {
        setIsLoading(true);
        const resp_data = await response.data;
        const next = resp_data.next_page;
        const data = resp_data.data.map(
          material_type === "wall"
            ? (info: WallsColorRecordT) => ({
                id: info.id,
                name: info.name,
                hexCode: info.hexCode,
                skuNumber: info.skuNumber,
                logo: info.logo,
                rgbCode: info.rgbCode,
                urlOnVendorPage: info.urlOnVendorPage,
                vendorName: info.vendorName,
              })
            : (info: FloorsColorRecordT) => ({
                id: info.id,
                name: info.name,
                type: info.type,
                floorIcon: info.floorIcon,
                logo: info.logo,
                code: info.code,
                urlOnVendorPage: info.urlOnVendorPage,
                vendorName: info.vendorName,
              })
        );
        if (data) {
          if (material_type === "wall") {
            dispatch(set_random_walls(data));
            dispatch(get_next_random_walls(next));
          } else {
            dispatch(set_random_tiles(data));
            dispatch(get_next_random_tiles(next));
          }
        }
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(
        error.message || `Wrong ${material_type} colors,  please try again`
      );
    } finally {
      setIsLoading(false);
    }
  };

export const get_next_random_walls_tiles_url =
  (
    data: [] | WallColorDataI[] | TileTypeDataI[],
    material_type: string,
    link: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await fetchingDataWithAxiosMiddleware(
        "GET",
        `${ServiceApi.get_next_random_walls_tiles_url(link)}`
      );

      if (response?.status) {
        const resp_data = await response.data;
        const next = resp_data.next_page;
        const new_data = await resp_data.data.map(
          material_type === "wall"
            ? (info: WallsColorRecordT) => ({
                id: info.id,
                name: info.name,
                hexCode: info.hexCode,
                skuNumber: info.skuNumber,
                logo: info.logo,
                rgbCode: info.rgbCode,
                urlOnVendorPage: info.urlOnVendorPage,
                vendorName: info.vendorName,
              })
            : (info: FloorsColorRecordT) => ({
                id: info.id,
                name: info.name,
                type: info.type,
                floorIcon: info.floorIcon,
                logo: info.logo,
                code: info.code,
                urlOnVendorPage: info.urlOnVendorPage,
                vendorName: info.vendorName,
              })
        );

        if (new_data) {
          if (material_type === "wall") {
            dispatch(set_random_walls([...data, ...new_data]));
            dispatch(get_next_random_walls(next));
          } else {
            dispatch(set_random_tiles([...data, ...new_data]));
            dispatch(get_next_random_tiles(next));
          }
        }
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(
        error.message || `Wrong ${material_type} colors,  please try again`
      );
    }
  };

export const change_wall_or_tile_new =
  (payload: ChangeWallORTileI) => async (dispatch: AppDispatch) => {
    try {
      const {
        wall,
        tile,
        sphere_endpoint,
        process_type,
        interactionSource,
        common,
        config,
      } = payload;
      process_type !== "stillphoto" &&
        ServiceDataControl.set_last_viewed_style({
          look: common.look,
          result: common.result,
          config,
        });
      dispatch(select_wall(wall));
      dispatch(select_tile(tile));
      dispatch(set_new_still_photo_url(""));
      dispatch(change_showed_sphere(true));
      const url = `${ServiceApi.get_changed_wall_tile_new()}?originalPhotoName=${sphere_endpoint}${
        wall.id ? `&wallColorID=${wall.id}` : ""
      }${
        tile.id ? `&floorTileID=${tile.id}` : ""
      }&type=${process_type}&interactionSource=${interactionSource}`;

      const response = await fetchingDataWithAxiosMiddleware("POST", url);

      const response_data: LoadingTimeResponseI = await response.data;

      // throw new Error("Hunt's error");
      ServiceDataControl.set_session_storage_item(
        "sphere_id",
        response_data.ID
      );
      dispatch(
        set_result_checker({
          called_count: 1,
          called_id: response_data,
        })
      );

      if (!common.loading_time.id) {
        dispatch(
          set_loading_time({
            id: response_data.ID,
            minutesRemaining: response_data.minutesRemaining,
            secondsRemaining: response_data.secondsRemaining,
          })
        );
      }

      const look = JSON.parse(String(sessionStorage.getItem("common")))?.look;
      ServiceDataControl.set_last_selected_wall_and_tile(
        look?.wallColor.id === null ? 0 : look?.wallColor.id,
        look?.floorTile.id === null ? 0 : look?.floorTile.id
      );
    } catch (err) {
      const error = err as AxiosError;
      dispatch(change_loading(false));
      ServiceDataControl.get_last_viewed_style(dispatch);
      dispatch(change_extra_modal_state());
      dispatch(is_clicked(false));
      dispatch(change_modal());
      toast.error(error.message || "Something went wrong, please try again");
    }
  };

export const get_user_count = () => async (dispatch: AppDispatch) => {
  try {
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      ServiceApi.get_user_count()
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_count(data));
      }
    }
  } catch (e) {
    const error = e as AxiosError;
    dispatch(change_loading(false));
    toast.error(
      error.message || "Something went wrong with looks, please try again"
    );
  }
};

export const update_user_count =
  (
    data: UserI,
    setCookie: (
      name: "email",
      value: any,
      options?: CookieSetOptions | undefined
    ) => any
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.update_user_count(),
        formData
      );

      if (response?.status) {
        setCookie("email", data.email, { expires: expires_on });
        dispatch(set_count(response.data.userCount));
        dispatch(set_user(response.data.name));
        dispatch(change_modal("Thanks"));
        dispatch(text_thanks("Please check your email for confirmation."));
        return true;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error("User by this email already exists"); //error.message ||
    }
  };
