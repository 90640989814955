import useTwoFingersStyle from "./MiniLoading/photosphereLoading.module.scss";
import Logo from "../../../Assets/Images/logo.svg";
import {AppDispatch} from "../../../Redux/store";
import {LookDataI} from "../../../Types";
import {change_material_type} from "../../../Redux/Actions";


export const green_dots_and_tag_markers_functional = (
    dispatch: AppDispatch,
    look: LookDataI,
    material_type: string
) => {
    const container_wall: HTMLElement | null =
        document.getElementById("container-wall");
    const container_tile: HTMLElement | null =
        document.getElementById("container-tile");

    let interval: NodeJS.Timeout | null = null;
    const time_out: number = 50;


    let link_out_accesses: {
        can_show_wall: boolean,
        can_show_tile: boolean
    } = {
        can_show_wall: true,
        can_show_tile: true
    }

    const handler_link_out_permission_wall = () => {
        interval = setTimeout(() => {
            link_out_accesses = {...link_out_accesses, can_show_wall: false}
            console.log("mousedown     <-------- MOUSE EVENT", link_out_accesses)
        }, time_out)
    }
    const handler_link_out_permission_tile = () => {
        interval = setTimeout(() => {
            link_out_accesses = {...link_out_accesses, can_show_tile: false}
            console.log("mousedown     <-------- MOUSE EVENT", link_out_accesses)
        }, time_out)
    }
    const wall_marker_functional = () => {
        if (interval) {
            clearTimeout(interval!);
        }
        if (material_type === "tile") {
            dispatch(change_material_type("wall"));
        }
        if (material_type === "wall") {
            if (link_out_accesses.can_show_wall) {
                window.open(look?.wallColor.urlOnVendorPage, "_blank");
            }
        }
        link_out_accesses = {...link_out_accesses, can_show_wall: true}
    }

    const tile_marker_functional = () => {
        if (interval) {
            clearTimeout(interval!);
        }
        if (material_type === "wall") {
            dispatch(change_material_type("tile"));
        }
        if (material_type === "tile") {
            if (link_out_accesses.can_show_tile) {
                window.open(look?.floorTile.urlOnVendorPage, "_blank");
            }
        }
        link_out_accesses = {...link_out_accesses, can_show_tile: true}
    }


    if (container_wall) {
        container_wall.addEventListener("mousedown", () => {
            handler_link_out_permission_wall()
        })
        container_wall.addEventListener("touchstart", () => {
            handler_link_out_permission_wall()
        })
        container_wall.addEventListener("mouseup", () => {
            wall_marker_functional()
        })
        container_wall.addEventListener("touchend", () => {
            wall_marker_functional()
        })
    }
    if (container_tile) {
        container_tile.addEventListener("mousedown", () => {
            handler_link_out_permission_tile()
        })
        container_tile.addEventListener("touchstart", () => {
            handler_link_out_permission_tile()
        })
        container_tile.addEventListener("mouseup", () => {
            tile_marker_functional()
        })
        container_tile.addEventListener("touchend", () => {
            tile_marker_functional()
        })
    }
};

export const use_two_fingers = () => {
    let use_two_fingers_div_list: NodeListOf<HTMLDivElement> =
        window.document.querySelectorAll(".psv-overlay");
    use_two_fingers_div_list.forEach((use_two_fingers) => {
        if (use_two_fingers !== null) {
            const psv_overlay_image_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-image"),
                psv_overlay_title_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-title"),
                psv_overlay_text_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-text");
            psv_overlay_image_div_list.forEach((psv_overlay_image) => {
                if (psv_overlay_image !== null) {
                    psv_overlay_image.style.display = "none";
                }
            });
            psv_overlay_title_div_list.forEach((psv_overlay_title) => {
                if (psv_overlay_title !== null) {
                    psv_overlay_title.style.display = "none";
                }
            });
            psv_overlay_text_div_list.forEach((psv_overlay_text) => {
                if (psv_overlay_text !== null) {
                    psv_overlay_text.style.display = "none";
                }
            });
            use_two_fingers.style.opacity = "1";
            const container: HTMLDivElement = document.createElement("div");
            container.className = useTwoFingersStyle.use_two_fingers_container;
            const logo: HTMLImageElement = document.createElement("img");
            logo.className = useTwoFingersStyle.logo;
            logo.src = Logo;
            container.appendChild(logo);
            use_two_fingers.append(container);
        }
    });
};
