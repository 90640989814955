import { FC } from "react";
import loadingStyles from "./photosphereLoading.module.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { typedSelector } from "../../../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { change_modal } from "../../../../Redux/Actions";
import useLoadingTimer from "../../../../Hooks/useLoadingTimer";

interface PropsI {
  sphere_type: string;
}

const ViewerLoading: FC<PropsI> = (props) => {
  const { sphere_type } = props;
  const dispatch = useDispatch<AppDispatch>();
  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);
  const sphere_statuses_new_sphere = typedSelector(
    (state) => state.config.sphere_statuses.new_sphere
  );
  const { loading_time, loading } = useLoadingTimer(sphere_type);

  if (!sphere_statuses_new_sphere) {
    return (
      <div
        className={
          config.current_process_type === "still photo" ||
          config.current_process_type === "upload look" ||
          (sphere_type === "new" && common.loading_time.secondsRemaining) > 44
            ? loadingStyles.loading_container_with_text_me
            : loadingStyles.loading_container
        }
      >
        <h1 className={loadingStyles.loading_text}>Your Space Is Loading...</h1>
        <CircularProgressbar
          value={loading.step}
          minValue={0}
          maxValue={loading_time.circle_length}
          strokeWidth={50}
          styles={buildStyles({
            rotation: 0,
            strokeLinecap: "butt",
            textSize: "20px",
            pathTransitionDuration: 1,
            pathColor: `#59D07F`,
            textColor: `${(sphere_type === "new" &&
                    common.loading_time.secondsRemaining) > 44
                    ? "#00140A"
                    : "#fff"}`,
            trailColor: "none",
          })}
        />
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'inherit', fontSize: '20px' }}>
            {common.loading_time.id &&
              sphere_type === "new" &&
              loading_time.circle_length > 45
                ? `${loading_time.min}:${
                    loading_time.sec < 10
                      ? `0${loading_time.sec}`
                      : loading_time.sec
              }`
            : ""}
          </div>
        <div className={loadingStyles.text_me_block}>
          <h1 className={loadingStyles.text_me_title}>In a rush?</h1>
          <button
            onClick={() => dispatch(change_modal("LinkMe"))}
            className={loadingStyles.text_me_button}
          >
            Text Me When It's Ready
          </button>
        </div>
      </div>
    );
  }
  return <></>;
};
export default ViewerLoading;
