import {
  LookDataI,
  RCommonStateI,
  RConfigStateI,
  ResultDataI,
  SphereDataI,
} from "../../Types";

export const common_reducer_initial_state: RCommonStateI = {
  look: {
    id: 0,
    url: "",
    urlWithLogos: "",
    floorTile: {
      id: 0,
      name: "",
      code: "",
      type: "",
      logo: "",
      floorIcon: "",
      is_look_tile: false,
      urlOnVendorPage: "",
      vendorName: "",
    },
    wallColor: {
      id: 0,
      name: "",
      type: "",
      hexCode: "",
      skuNumber: "",
      logo: "",
      rgbCode: [0, 0, 0],
      urlOnVendorPage: "",
      vendorName: "",
    },
  },
  logos_positions: {
    rotate_sphere: [0, 0],
    walls_marker: [1.6780415086248932, -0.22584317164642664],
    floor_marker: [1.5466602241635017, -1.0929227440372014],
  },
  sphere: {
    id: 0,
    name: "",
    sphereUrl: "",
    thumbnailUrl: "",
  },
  result: {
    sphere_endpoint: "",
    still_photo_endpoint: "",
    old_sphere_url: "",
    new_sphere_url: "",
    old_still_photo_url: "",
    new_still_photo_url: "",
  },
  looks: [],
  spheres: [],
  random_walls: [],
  random_tiles: [],
  result_checker_state: {
    called_count: 0,
    called_id: {
      ID: 0,
      minutesRemaining: 0,
      secondsRemaining: 0,
    },
  },
  upload_look_checker_state: {
    called_id: {
      ID: 0,
      minutesRemaining: 0,
      secondsRemaining: 0,
    },
    called_count: 0,
  },
  uploaded_look_url: "",
  next_random_walls: "",
  next_random_tiles: "",
  loading_time: {
    id: 0,
    minutesRemaining: 0,
    secondsRemaining: 0,
  },
  still_photo_logo_location: {
    wall_marker: {
      top: 45,
      left: 57,
    },
    floor_marker: {
      top: 91,
      left: 41,
    },
  },
};

export const common_reducer_initial_look_data: LookDataI = {
  id: 0,
  url: "",
  urlWithLogos: "",
  floorTile: {
    id: 0,
    name: "",
    type: "",
    code: "",
    logo: "",
    floorIcon: "",
    is_look_tile: false,
    urlOnVendorPage: "",
    vendorName: "",
  },
  wallColor: {
    id: 0,
    type: "",
    name: "",
    hexCode: "",
    skuNumber: "",
    logo: "",
    rgbCode: [0, 0, 0],
    urlOnVendorPage: "",
    vendorName: "",
  },
};

export const common_reducer_initial_sphere_data: SphereDataI = {
  id: 0,
  name: "",
  sphereUrl: "",
  thumbnailUrl: "",
};
export const common_reducer_initial_result_data: ResultDataI = {
  sphere_endpoint: "",
  old_sphere_url: "",
  new_sphere_url: "",
  old_still_photo_url: "",
  new_still_photo_url: "",
  still_photo_endpoint: "",
};

export const config_reducer_initial_state: RConfigStateI = {
  message: "",
  load_time: 0,
  loading: false,
  text_thanks: "",
  is_random: true,
  clicked: false,
  is_new_sphere: true,
  upload_modal: true,
  look_uploaded: false,
  extra_modal_type: "",
  is_still_photo: false,
  is_reminder: false,
  sphere_uploaded: false,
  modal_type: "Inspiration",
  is_second_page_start: false,
  quotes_modal_is_open: false,
  close_modal_type: "",
  material_type: "wall",
  count: 0,
  name: "",
  more_text: "",
  is_shared_page: false,
  sphere_statuses: {
    new_sphere: false,
    old_sphere: false,
  },
  current_process_type: "",
  is_desktop_use: false,
  can_show_360_page: false,
  is_full_screen_enabled: false,
};
