import React, { ChangeEvent, FC, useState } from "react";
import paintFloorStyle from "./getPaintAndFloor.module.scss";
import { Customer_InfoI, GetPaintAndFloorI } from "../../../Types";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { useAsyncActions } from "../../../Hooks/useActions";
import { isValidate } from "../../../Common/Config";
import useDebounce from "../../../Hooks/useDebounce";
import { toast } from "react-hot-toast";
import VendorsInfo from "../../WallsAndTiles/vendorsInfo";

const GetPaintAndFloor: FC<GetPaintAndFloorI> = ({ props }) => {
  const { send_customer_form } = useAsyncActions();
  const { wallColor, floorTile } = typedSelector((state) => state.common.look);
  const color_id = props === "wall" ? wallColor.id : floorTile.id;
  const material = props === "wall" ? "paint" : "floor";

  const [customer_info, set_customer_info] = useState<Customer_InfoI>({
    props: "",
    name: "",
    phone: "",
    error: "",
    id: "",
  });

  const check_form = () => {
    if (isValidate("number", customer_info.phone) && !!customer_info.name) {
      send_customer_form({
        props: material,
        name: debounced_name,
        phone: debounced_phone,
        error: "",
        id: color_id,
      });
    } else {
      toast.error(
        customer_info.name ? "Check your phone number" : "Name is required"
      );
      set_customer_info({
        ...customer_info,
        error: "Check your phone number",
      });
    }
  };

  const debounced_name = useDebounce<string>(customer_info.name, 500);
  const debounced_phone = useDebounce<string>(customer_info.phone, 500);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    set_customer_info({ ...customer_info, name: e.target.value });
  };

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    set_customer_info({
      ...customer_info,
      phone: e.target.value,
    });
  };

  return (
    <div className={paintFloorStyle.component}>
      <div onClick={(e) => e.stopPropagation()} className={paintFloorStyle.box}>
        <div className={paintFloorStyle.content}>
          <p>Get samples, coupons, </p>
          <p>and best pricing via text.</p>
          <VendorsInfo />
          <div className={paintFloorStyle.box}>
            <input
              id="name"
              type="text"
              placeholder="*Name"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleNameChange(e)
              }
            />
            <input
              id="phone"
              type="number"
              placeholder="*Mobile Number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangePhone(e)
              }
            />
            <div className={paintFloorStyle.button} onClick={check_form}>
              Get!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetPaintAndFloor;
