import { ChangeEvent, FC } from "react";
import captureSpaceStyle from "./captureSpace.module.scss";
import { useAsyncActions } from "../../../Hooks/useActions";
import {
  change_extra_modal_state,
  is_clicked,
  set_360_page_config,
  set_random,
} from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { set_still_photo_base_64 } from "../../../Redux/Actions/CommonActions/assetsAction";

const CaptureSpace: FC = () => {
  const { upload_still_photo, change_wall_or_tile_new } = useAsyncActions();
  const dispatch = useDispatch<AppDispatch>();
  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);

  const handleUploadStill = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let baseString;
      reader.onloadend = function () {
        baseString = reader.result || "";
        dispatch(set_random(false));
        dispatch(set_still_photo_base_64(baseString));
      };
      reader.readAsDataURL(e.target.files[0]);
      upload_still_photo(
        e.target.files[0],
        change_wall_or_tile_new,
        common,
        config
      );
    }
  };

  const open360Photo = () => {
    dispatch(set_360_page_config(true));
    dispatch(change_extra_modal_state(""));
  };

  const handleBack = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(is_clicked(false));
    dispatch(change_extra_modal_state(""));
  };

  return (
    <div className={captureSpaceStyle.container}>
      <div className={captureSpaceStyle.button_box}>
        <div
          className={captureSpaceStyle.button}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={captureSpaceStyle.box}>
            <label
              htmlFor="upload_still_photo"
              className={captureSpaceStyle.upload}
            >
              <input
                id="upload_still_photo"
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUploadStill(e)
                }
              />
            </label>
          </div>
        </div>
        <div
          className={captureSpaceStyle.liner}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={captureSpaceStyle.box1} onClick={open360Photo} />
        </div>
      </div>
      <p onClick={(e) => handleBack(e)}>Back</p>
    </div>
  );
};

export default CaptureSpace;
