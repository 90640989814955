import React, {FC, useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {Navigate, NavigateFunction, Route, Routes, useNavigate,} from "react-router";
import SharedPage from "./Component/SharedPage/sharedPage";
import QR from "./Component/Qr/qr";
import List from "./Component/List/list";
import {routes} from "./Common/Config";
import {ServiceDataControl} from "./Services";
import Auth from "./Component/Auth/auth";
import ScreenOrientation from "./Common/ScreenOrientation/screenOrientations";
import SharedPage_Type_2 from "./Component/SharedPage/sharePage_Type2";

const App: FC = () => {
  //Chjnjel
  const navigate: NavigateFunction = useNavigate();

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    ServiceDataControl.change_page_settings(true);
    event.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !localStorage.getItem("token") ? (
              <Auth/>
            ) : window.innerWidth <= 850 ? (
              <List/>
            ) : (
              <QR/>
            )
          }
        />
        <Route
          path={routes.home}
          element={window.innerWidth <= 850 ? <List/> : <QR/>}
        />
        {/*<Route path={routes.home} element={<List/>}/>*/}
        <Route path={routes.shared_third_page} element={<SharedPage/>}/>
        <Route path={"shared/:id"} element={<SharedPage_Type_2/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
      <Toaster
        toastOptions={{duration: 3000}}
        position="top-center"
        reverseOrder={false}
      />
      <ScreenOrientation/>
    </>
  );
};


export default App;
