import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import spaceStyles from "./spaces.module.scss";
import { AppDispatch } from "../../../Redux/store";
import {
  change_extra_modal_state,
  change_modal,
  is_clicked,
  set_random,
} from "../../../Redux/Actions";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { choose_hard_coded_sphere } from "../../../Common/Config";
import { useAsyncActions } from "../../../Hooks/useActions";
import { SphereDataI } from "../../../Types";

const Spaces: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { change_wall_or_tile_new, get_all_spheres } = useAsyncActions();
  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);
  const { spheres, sphere } = common;

  useEffect(() => {
    if (!common.spheres.length) {
      get_all_spheres();
    }
  }, []);

  const handleRoom = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    spheres: SphereDataI
  ) => {
    e.stopPropagation();
    choose_hard_coded_sphere(
      sphere.id,
      dispatch,
      common,
      config,
      spheres,
      change_wall_or_tile_new
    );
    dispatch(set_random(false));
  };

  const handleSeeInSpace = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(change_extra_modal_state("Capture"));
  };

  const handleBack = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(is_clicked(false));
    dispatch(change_modal("close"));
  };

  return (
    <div className={spaceStyles.container}>
      <p>DEMO ROOMS</p>
      <div className={spaceStyles.spaces_box1}>
        <div
          onClick={(e) => handleRoom(e, spheres[0])}
          className={spaceStyles.one}
        >
          <img
            className={spaceStyles.livingRoom}
            src={spheres[0]?.thumbnailUrl}
            alt="livingRoom"
          />
          <div className={spaceStyles.gradient} />
        </div>
        <div
          onClick={(e) => handleRoom(e, spheres[3])}
          className={spaceStyles.two}
        >
          <img
            className={spaceStyles.bathroom}
            src={spheres[3]?.thumbnailUrl}
            alt="bathroom"
          />
          <div className={spaceStyles.gradient} />
        </div>
      </div>
      <div className={spaceStyles.spaces_box2}>
        <div
          onClick={(e) => handleRoom(e, spheres[2])}
          className={spaceStyles.three}
        >
          <img
            className={spaceStyles.office}
            src={spheres[2]?.thumbnailUrl}
            alt="office"
          />
          <div className={spaceStyles.gradient} />
        </div>
        <div
          onClick={(e) => handleRoom(e, spheres[1])}
          className={spaceStyles.four}
        >
          <img
            className={spaceStyles.bedroom}
            src={spheres[1]?.thumbnailUrl}
            alt="bedroom"
          />
          <div className={spaceStyles.gradient} />
        </div>
      </div>
      <div className={spaceStyles.div}>
        <div className={spaceStyles.or}>OR</div>
        <div className={spaceStyles.button} onClick={(e) => handleSeeInSpace(e)}>
          See in My Space
        </div>
        <p onClick={(e) => handleBack(e)}>Back</p>
      </div>
    </div>
  );
};

export default Spaces;
