import {NavigateFunction, useNavigate, useParams} from "react-router";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../Redux/store";
import React, {useEffect} from "react";
import {LookDataI, ResultDataI} from "../../Types";
import {ServicePagesControl} from "../../Services";


export interface FaceDataI {
  process_id: number | string;
  look: LookDataI;
  result: ResultDataI;
  is_still_photo: boolean;
  is_custom_look: boolean;
  still_photo_logo_location: any;
  logos_positions: any,
  loading_time: any
}

const is_sphere: boolean = true

const fakeData: FaceDataI = {
  process_id: 2655,
  look: {
    id: 23,
    url: "https://public.dospace.tech/look_images/hardcoded_looks/looks_v2/hardcoded_look23.jpg",
    urlWithLogos: "null",
    floorTile: {
      code: " U75665",
      floorIcon: "https://public.stagnyc.com/logo_images/floor_thumbnails/2023_09_11_08_59_05_328.jpg",
      id: 551,
      is_look_tile: false,
      logo: "https://public.stagnyc.com/logo_images/logos/2023_09_10_08_33_05_986.jpg",
      name: " Upscape Bruno",
      type: "WOOD",
      urlOnVendorPage: "https://redirect.viglink.com?u=https%3A%2F%2Fwww.wayfair.com%2Fhome-improvement%2Fpdp%2Fmsi-upscape-6-x-40-porcelain-wood-look-wall-floor-tile-mvp3623.html%3Fpiid%3D40588584&key=5a96913fbd4adb59a834b2bbb5fe0006",
      vendorName: "Wayfair Wood_test",
    },
    type: "",
    wallColor: {
      id: 1377,
      type: "",
      hexCode: "#d9ae69",
      skuNumber: "467561",
      rgbCode: [215, 172, 103],
      vendorName: "Glidden_test",
      name: "Butterscotch Bliss Yellow Flat",
      logo: "https://public.stagnyc.com/logo_images/logos/2023_08_01_11_26_54_306.jpg",
      urlOnVendorPage: "https://redirect.viglink.com?u=https%3A%2F%2Fwww.walmart.com%2Fip%2FGlidden-Fundamentals-Interior-Paint-Butterscotch-Bliss-Yellow-Flat-1-Gallon%2F1692315298%3Ffrom%3D%2Fsearch&key=5a96913fbd4adb59a834b2bbb5fe0006"
    }
  },
  is_custom_look: false,
  is_still_photo: !is_sphere,
  result: is_sphere ? {
      new_sphere_url: "https://public.stagnyc.com/photosphere_results_hardcoded/demo_sample_1_wallcolor1377_floortile551.jpg",
      new_still_photo_url: "",
      old_sphere_url: "https://public.stagnyc.com/hardcoded_spheres/demo_sample_1.jpg",
      old_still_photo_url: "",
      sphere_endpoint: "demo_sample_1",
      still_photo_endpoint: "",
    }
    :
    {
      new_sphere_url: "",
      new_still_photo_url: "https://public.stagnyc.com/still_images/2023_11_09_08_39_18_393_wallcolor1377_floortile551.jpg",
      old_sphere_url: "",
      old_still_photo_url: "https://public.stagnyc.com/still_images/2023_11_09_08_39_18_393.jpg",
      sphere_endpoint: "",
      still_photo_endpoint: "2023_11_09_08_39_18_393",
    },
  still_photo_logo_location: {
    floor_marker: {top: 91, left: 41},
    wall_marker: {top: 45, left: 57}
  },
  loading_time: {
    id: 2653,
    minutesRemaining: 0,
    secondsRemaining: 40
  },
  logos_positions: {
    floor_marker: [2.14, -0.6],
    rotate_sphere: [2.7, -0.3],
    walls_marker: [3.14, 0.2]
  },
}


const SharedPage_Type_2 = () => {
  const navigate: NavigateFunction = useNavigate();
  const url_params = useParams();
  const dispatch = useDispatch<AppDispatch>();


  // console.log(url_params, "<--------------------------- PARAMS OF URL")

  useEffect(() => {
    ServicePagesControl.open_shared_page(fakeData, navigate)
  }, [])


  return <></>;
};

export default SharedPage_Type_2;