import { AppDispatch } from "../Redux/store";
import { ServiceApi } from "../Services";
import { LookDataI, SphereDataI } from "../Types";
import {
  set_old_sphere_url,
  set_sphere,
  set_sphere_type,
  set_still_photo_type,
} from "../Redux/Actions";

export class SphereController {
  handler_sphere = (
    sphere: SphereDataI,
    dispatch: AppDispatch,
    look: LookDataI,
    look_uploaded: boolean
  ): void => {
    dispatch(
      set_old_sphere_url({
        url: `${ServiceApi.get_hardcoded_spheres_image_source()}/${
          sphere?.name
        }.jpg`,
        endpoint: sphere?.name,
      })
    );
    dispatch(set_still_photo_type(false));
    dispatch(set_sphere_type());
    dispatch(set_sphere(sphere));
  };
}
