import React from "react";
import screenOrientationStyle from "./screenOrientations.module.scss";
import useDeviceTypeChecker from "../../Hooks/useDeviceTypeChecker";
import { typedSelector } from "../../Hooks/useTypedSelector";

const ScreenOrientation = () => {
  const { is_portrait } = useDeviceTypeChecker();
  const is_full_screen_enabled = typedSelector(
    (state) => state.config.is_full_screen_enabled
  );
  if (!is_portrait && !is_full_screen_enabled) {
    return (
      <div className={screenOrientationStyle.main}>
        <div className={screenOrientationStyle.phone}></div>
        <div className={screenOrientationStyle.message}>
          Please rotate your device!
        </div>
      </div>
    );
  }
  return <></>;
};

export default ScreenOrientation;
