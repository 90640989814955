import toast from "react-hot-toast";
import { AppDispatch } from "../../store";
import { AxiosError, CancelToken } from "axios";
import { ResultCheckerI } from "../../../Types";
import { fetchingDataWithAxiosMiddleware } from "./commonAction";
import { ServiceApi, ServiceDataControl } from "../../../Services";
import {
  set_result_checker,
  set_still_photo_logo_location,
} from "../CommonActions/commonAction";
import { set_new_still_photo_url } from "../CommonActions/stillPhotoAction";
import {
  set_all_spheres,
  set_new_sphere_url,
} from "../CommonActions/sphereAction";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  is_clicked,
  set_wall_tile_logos_positions,
} from "../CommonActions/configAction";

export const get_all_spheres = () => async (dispatch: AppDispatch) => {
  try {
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      ServiceApi.get_all_spheres_url()
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_all_spheres(data));
      }
    }
  } catch (e) {
    const error = e as AxiosError;
    dispatch(change_loading(false));
    toast.error(
      error.message || "Something went wrong with spheres, please try again"
    );
  }
};

let timer: NodeJS.Timeout | undefined = undefined;

export const get_sphere_name =
  (payload: {
    result_checker_state: ResultCheckerI;
    process_type: string;
    cancelToken: CancelToken;
    is_reminder: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    const { result_checker_state, process_type, cancelToken, is_reminder } =
      payload;
    try {
      const sphere = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_apply_look_result(
          `${result_checker_state.called_id.ID}`
        ),
        undefined,
        cancelToken
      );

      if (sphere.status === 200) {
        let sphere_information;
        if (process_type === "hardcodedsphere") {
          const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            ServiceApi.get_positions_of_logo(sphere.data?.photo_name)
          );
          sphere_information = await response.data;

          dispatch(
            set_new_sphere_url(
              ServiceApi.read_new_sphere_url(sphere.data?.photo_name)
            )
          );
          dispatch(
            set_wall_tile_logos_positions(sphere_information.logo_location)
          );
        } else if (process_type === "stillphoto") {
          dispatch(set_still_photo_logo_location(sphere.data?.logo_location));
          dispatch(
            set_new_still_photo_url(
              ServiceApi.read_new_still_photo_url(sphere.data?.photo_name)
            )
          );
        }

        const look = JSON.parse(String(sessionStorage.getItem("common")))?.look;

        ServiceDataControl.set_last_selected_wall_and_tile(
          look?.wallColor.id === null ? 0 : look?.wallColor.id,
          look?.floorTile.id === null ? 0 : look?.floorTile.id
        );
        dispatch(
          set_result_checker({
            called_count: 0,
            called_id: {
              ID: 0,
              minutesRemaining: 0,
              secondsRemaining: 0,
            },
          })
        );
        dispatch(change_modal());
        dispatch(change_extra_modal_state());
        dispatch(change_loading(false));
      } else if (sphere.status === 202 && !is_reminder) {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          dispatch(
            set_result_checker({
              called_count: result_checker_state.called_count + 1,
              called_id: result_checker_state.called_id,
            })
          );
        }, 3000);
      }
    } catch (err) {
      const error = err as AxiosError;
      if (timer) {
        clearTimeout(timer);
      }
      dispatch(change_loading(false));
      ServiceDataControl.get_last_viewed_style(dispatch);
      dispatch(is_clicked(false));
      dispatch(
        set_result_checker({
          called_count: 0,
          called_id: {
            ID: 0,
            minutesRemaining: 0,
            secondsRemaining: 0,
          },
        })
      );
      toast.error(error.message || "Something went wrong, please try again");
    }
  };
