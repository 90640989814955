import {useEffect, useLayoutEffect, useState} from "react";


const useDeviceTypeChecker = () => {
    const [is_portrait, set_orientation_type] = useState<boolean>(true);
    const [deviceType, setDeviceType] = useState<string>("");


    useLayoutEffect(() => {
        window.screen.orientation.addEventListener('change', (e) => {
            if (window.innerWidth < 850) {
                // @ts-ignore
                if ("target" in e && "type" in e?.target) {
                    //@ts-ignore
                    set_orientation_type(e.target.type !== "landscape-primary")
                }
            } else {
                set_orientation_type(true)
            }
        })
        window.addEventListener("resize", () => {
            if (window.innerWidth < 850) {
                if (window.matchMedia("(orientation: portrait)").matches) {
                    // console.log('orientation: portrait');
                    set_orientation_type(true);
                }

                if (window.matchMedia("(orientation: landscape)").matches) {
                    // console.log('orientation: landscape');
                    set_orientation_type(false);
                }
            } else {
                set_orientation_type(true)
            }
        });
    }, []);


    // DEVICE TYPE
    useEffect(() => {
        const platform = navigator.userAgent;
        setDeviceType(
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                platform
            )
                ? "mobile"
                : "desktop"
        );
    }, [window.innerWidth]);

    return {deviceType, is_portrait};
};

export default useDeviceTypeChecker;