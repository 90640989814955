import {
  ChangeMarkerI,
  FloorTileI,
  WallColorDataI,
  WallTileLogosPositionsI,
} from "../../Types";
import green_dote from "../../Assets/Images/Green_dote.png";
import marker from "../../Assets/Images/marker.png";
import {
  handler_marker_text,
  material_name_cut_number,
  vendor_name_cut_number,
} from "../Markers/markersConfig";

let x = 0;
export const change_marker = (
  id: string,
  info: WallColorDataI | FloorTileI,
  logos_positions: WallTileLogosPositionsI,
  is_wall: string
): ChangeMarkerI => {
  x = (x + 1) / 2;
  return {
    id: `${id}-marker`,
    position: {
      yaw:
        id === "wall"
          ? logos_positions.walls_marker[0]
          : logos_positions.floor_marker[0],
      pitch:
        id === "wall"
          ? logos_positions.walls_marker[1]
          : logos_positions.floor_marker[1],
    },
    html: `
            <div 
                style="
                        position: relative;
                        padding: 5px;
                        min-width: 150px;
                        min-height: 150px;
                        color: white;
                        display:flex;
                        flex-direction: column;
                        justify-content: center
                      "
            >
                  <div id="container-${id}">
                        <div style="width: 100%; display: flex; align-items: center">
                              <img 
                                id="green_dote_marker_${id}"
                                src="${green_dote}" 
                                style="
                                        cursor: pointer;
                                        width: 25px;
                                        height: 18px; 
                                        object-fit: cover; 
                                        margin: 0 5px 0 0;
                                      " 
                                alt="green dote"
                              />
                          <p id="${id}-name" style="
                                                  font-weight: ${
                                                    is_wall === "wall" &&
                                                    id === "wall"
                                                      ? "600"
                                                      : is_wall === "tile" &&
                                                        id === "tile"
                                                      ? "600"
                                                      : "400"
                                                  };
                                                  margin: 0; 
                                                  padding: 0
                                                  " >${
                                                    info.id
                                                      ? handler_marker_text(
                                                          material_name_cut_number,
                                                          info.name
                                                        )
                                                      : "None"
                                                  }
                          </p>
                      </div>
                      ${
                        info.id
                          ? `<h1 id="${id}-vendor-name" style="
                                                                                          padding: 0;
                                                                                          color: #FFF;
                                                                                          font-size: 11px;
                                                                                          font-weight: 400;
                                                                                          margin: -2px 0 0 30px;
                                                                                          font-style: normal;
                                                                                          line-height: normal;
                                                                                          text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
                                                                                        "
                                                                              >by ${
                                                                                "vendorName" in
                                                                                info
                                                                                  ? handler_marker_text(
                                                                                      vendor_name_cut_number,
                                                                                      info.vendorName
                                                                                    )
                                                                                  : ""
                                                                              } </h1>`
                          : ""
                      }
                                                              <div
                                                                style="position:relative;width: 100%; "
                                                              >
                                                        ${
                                                          is_wall === "wall" &&
                                                          id === "wall" &&
                                                          info.id
                                                            ? `<img
                                                                id="wall_tag_marker"
                                                                src="${marker}"
                                                                style="
                                                                      position: absolute;
                                                                      cursor: pointer;
                                                                      left: 25px;
                                                                      bottom: -30px;
                                                                      width: 30px;
                                                                      height: 30px;
                                                                      object-fit: cover;
                                                                   "
                                                                alt="wall material"
                                                             />`
                                                            : is_wall ===
                                                                "tile" &&
                                                              id === "tile" &&
                                                              info.id
                                                            ? `<img   
                                                                      id="tile_tag_marker"
                                                                      src="${marker}"
                                                                      style="
                                                                              position: absolute;
                                                                              cursor: pointer;
                                                                              left: 25px;
                                                                              bottom: -30px;
                                                                              width: 30px;
                                                                              height: 30px;
                                                                              object-fit: cover;
                                                                             "
                                                                    alt="wall material"
                                                                  />`
                                                            : ""
                                                        }
                      </div>  
                  </div>
               </div>
            `,
  };
};
