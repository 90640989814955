import {useEffect, useState} from "react";
import {typedSelector} from "../../../Hooks/useTypedSelector";

const still_image_container_default_padding_width: number = 40;

const UseImageProportion = () => {
    const [container_min_height, set_uploaded_image_height] = useState<number>(470);
    const result = typedSelector((state) => state.common.result);
    const config = typedSelector((state) => state.config);
    const still_photo_base_64 = typedSelector((state) => state.assets.still_photo_base_64);
    const handler_image = async (photo: string) => {
        const img = new Image();
        img.src = photo;
        await img.decode();
        const width = img.width, height = img.height;
        return {
            width,
            height
        };
    };


    useEffect(() => {
        if (still_photo_base_64) {
            handler_image(still_photo_base_64).then(resp => {
                const {width, height} = resp;
                const container_width: number = window.innerWidth - still_image_container_default_padding_width,
                    delta = width / container_width,
                    min_height = Math.floor(height / delta);
                set_uploaded_image_height(min_height)
            });
        }
        if (config.is_shared_page) {
            handler_image(result.new_still_photo_url).then(resp => {
                const {width, height} = resp;
                const container_width: number = window.innerWidth - still_image_container_default_padding_width,
                    delta = width / container_width,
                    min_height = Math.floor(height / delta);
                set_uploaded_image_height(min_height)
            });
        }
    }, [still_photo_base_64]);

    return container_min_height

}

export default UseImageProportion