import { FC, useEffect, useState } from "react";
import circleStyle from "./circles.module.scss";
import { useAsyncActions } from "../../../Hooks/useActions";
import { TileTypeDataI, WallColorDataI } from "../../../Types";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import CustomHorizontalScroll from "./horizontalInfiniteScroll";
import { set_random_tiles, set_random_walls } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";

const Circles: FC<{ material_type: string }> = ({ material_type }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { get_random_walls_tiles, get_next_random_walls_tiles_url } =
    useAsyncActions();
  const common = typedSelector((state) => state.common);
  const {
    look,
    random_walls,
    random_tiles,
    next_random_walls,
    next_random_tiles,
  } = common;
  const { wallColor, floorTile } = look;

  const material_data: WallColorDataI[] | TileTypeDataI[] | [] =
    material_type === "wall"
      ? random_walls
      : material_type === "tile"
      ? random_tiles
      : [];

  const next_random =
    material_type === "wall" ? next_random_walls : next_random_tiles;

  useEffect(() => {
    const app_state = JSON.parse(String(sessionStorage.getItem("common")));

    if (
      !random_walls.length &&
      !random_tiles.length &&
      !app_state?.random_walls.length &&
      !app_state?.random_tiles.length
    ) {
      get_random_walls_tiles(1, 20, setIsLoading, "wall");
      get_random_walls_tiles(1, 20, setIsLoading, "tile");
    }
    if (
      !random_walls.length &&
      !random_tiles.length &&
      app_state?.random_walls.length &&
      app_state?.random_tiles.length
    ) {
      dispatch(set_random_walls(app_state?.random_walls));
      dispatch(set_random_tiles(app_state?.random_tiles));
    }
  }, [wallColor.hexCode, floorTile.code]);

  const initialData = Array.from({ length: 20 }, (_, index) => material_data);
  const [data, setData] = useState(initialData);
  const [hasMore, setHasMore] = useState(true);

  const fetchNextData = () => {
    get_next_random_walls_tiles_url(material_data, material_type, next_random);
    setTimeout(() => {
      const newData = Array.from({ length: 20 }, (_, index) => material_data);
      setData((prevData) => [...prevData, ...newData]);
      setHasMore(
        (prevHasMore) => prevHasMore && data.length + newData.length < 2000
      ); // Limit the total items
    }, 1500);
  };

  return (
    <div className={circleStyle.sphere_colors}>
      <CustomHorizontalScroll
        width={1000}
        onFetchNextData={fetchNextData}
        hasMore={hasMore}
        data={
          material_type === "wall"
            ? random_walls
            : material_type === "tile"
            ? random_tiles
            : []
        }
        material_type={material_type}
      />
    </div>
  );
};

export default Circles;
