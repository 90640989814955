import { ForwardRefExoticComponent, RefAttributes, useEffect } from "react";

const useAutorotate = (
  photoSphereRef:
    | ForwardRefExoticComponent<any & RefAttributes<unknown>>
    | undefined
) => {
  useEffect(() => {
    if (!photoSphereRef) return;
    const autorotate_plugin =
      //@ts-ignore
      photoSphereRef?.getPlugin("autorotate");
    const autorotate_div = document.getElementById("autorotate");
    if (autorotate_div) {
      autorotate_div.addEventListener("click", () => {
        autorotate_plugin.stop();
        autorotate_div.style.display = "none";
      });
      autorotate_div?.addEventListener("touchstart", () => {
        autorotate_plugin.stop();
        autorotate_div.style.display = "none";
      });
    }
  }, [photoSphereRef]);
};

export default useAutorotate;
