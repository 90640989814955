import {AxiosError} from "axios";
import toast from "react-hot-toast";
import {ServiceApi, ServiceDataControl} from "./index";
import {fetchingDataWithAxiosMiddleware} from "../Redux/Actions";
import {AppStateI, LookDataI, PagesParamInfoI, RCommonStateI, RConfigStateI, UrlParamsI,} from "../Types";
import {common_reducer_initial_state, config_reducer_initial_state,} from "../Common/Config/reducersConfig";
import {FaceDataI} from "../Component/SharedPage/sharePage_Type2";
import {NavigateFunction} from "react-router";

export class PagesControl {
  handler_old_photo_url(is_still_photo: boolean, sphere_url: string): string {
    const url: string = sphere_url.slice(0, sphere_url.indexOf("_w"));
    if (is_still_photo) {
      return `${ServiceApi.read_old_still_photo_url(url)}.jpg`;
    }
    return ServiceApi.read_old_sphere_url(url);
  }

  handler_pages_param(params: UrlParamsI) {
    const {sphere_info, sphere_type, look_info, look_type} = params;
    const is_still_photo: boolean = sphere_type === "still";

    let info: PagesParamInfoI = {
      look_url:
        look_type === "custom"
          ? ServiceApi.uploaded_look_url(look_info)
          : ServiceApi.read_hardcoded_look_url(look_info),

      wall_id: "",
      tile_id: "",

      new_still_photo_url: is_still_photo
        ? ServiceApi.read_new_still_photo_url(
          sphere_info.indexOf("wallcolor0_floortile0") > 0
            ? sphere_info
            : `${sphere_info}`
        )
        : "",
      old_still_photo_url: is_still_photo
        ? this.handler_old_photo_url(is_still_photo, sphere_info)
        : "",
      still_photo_endpoint: is_still_photo
        ? sphere_info.slice(0, sphere_info.indexOf("_w"))
        : "",

      new_sphere_url: is_still_photo
        ? ""
        : ServiceApi.read_new_sphere_url(sphere_info),
      old_sphere_url: is_still_photo
        ? ""
        : this.handler_old_photo_url(is_still_photo, sphere_info),
      sphere_endpoint: is_still_photo
        ? ""
        : sphere_info.slice(0, sphere_info.indexOf("_w")),

      look_uploaded: look_type === "custom",
      is_still_photo: is_still_photo,
    };

    for (
      let i = sphere_info.indexOf("lor");
      i < sphere_info.indexOf("floor");
      i++
    ) {
      if (parseInt(sphere_info[i]) >= 0) {
        info = {...info, wall_id: info.wall_id + sphere_info[i]};
      }
    }

    for (let i = sphere_info.indexOf("ile"); i < sphere_info.length; i++) {
      if (parseInt(sphere_info[i]) >= 0) {
        info = {...info, tile_id: info.tile_id + sphere_info[i]};
      }
    }

    return info;
  }

  async set_wall_info(app_state: AppStateI, wall_id: string): Promise<void> {
    try {
      if (parseInt(wall_id) !== 0) {
        const wall_data_response = await fetchingDataWithAxiosMiddleware(
          "GET",
          ServiceApi.get_wall_info_url(wall_id)
        );
        app_state.common = {
          ...app_state.common,
          look: {
            ...app_state.common.look,
            wallColor: {
              id: wall_data_response?.data.id,
              name: wall_data_response?.data.name,
              type: "",
              hexCode: wall_data_response?.data.hexCode,
              skuNumber: wall_data_response?.data.skuNumber,
              logo: wall_data_response?.data.logo,
              rgbCode: wall_data_response?.data.rgbCode,
              urlOnVendorPage: wall_data_response?.data.urlOnVendorPage,
              vendorName: wall_data_response?.data.vendorName,
            },
          },
        };
      }
      if (parseInt(wall_id) === 0) {
        app_state.common = {
          ...app_state.common,
          look: {
            ...app_state.common.look,
            wallColor: {
              id: 0,
              logo: "",
              name: "Blank",
              type: "",
              hexCode: "none",
              skuNumber: "",
              rgbCode: [0, 0, 0],
              urlOnVendorPage: "",
              vendorName: "",
            },
          },
        };
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong");
    }
  }

  async set_tile_info(app_state: AppStateI, tile_id: string): Promise<void> {
    try {
      if (parseInt(tile_id) !== 0) {
        const tile_data_response = await fetchingDataWithAxiosMiddleware(
          "GET",
          ServiceApi.get_tile_info_url(tile_id)
        );
        app_state.common = {
          ...app_state.common,
          look: {
            ...app_state.common.look,
            floorTile: {
              id: tile_data_response?.data.id,
              name: tile_data_response?.data.name,
              type: tile_data_response?.data.type,
              code: tile_data_response?.data.code,
              logo: tile_data_response?.data.logo,
              floorIcon: tile_data_response?.data.floorIcon,
              is_look_tile: false,
              urlOnVendorPage: tile_data_response?.data.urlOnVendorPage,
              vendorName: tile_data_response?.data.vendorName,
            },
          },
        };
      }
      if (parseInt(tile_id) === 0) {
        app_state.common = {
          ...app_state.common,
          look: {
            ...app_state.common.look,
            floorTile: {
              id: 0,
              logo: "",
              code: "none",
              name: "Blank",
              type: "Blank",
              is_look_tile: false,
              floorIcon: "",
              urlOnVendorPage: "",
              vendorName: "",
            },
          },
        };
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong");
    }
  }

  async set_random_walls_and_tiles(
    app_state: {
      common: RCommonStateI;
      config: RConfigStateI;
    },
    default_look_wall_id: number,
    default_look_tile_id: number,
    selected_wall_color_id: number,
    selected_tile_color_id: number
  ): Promise<void> {
    try {
      const is_default_look_wall: boolean =
          default_look_wall_id === selected_wall_color_id,
        is_default_look_tile: boolean =
          default_look_tile_id === selected_tile_color_id;

      if (!is_default_look_wall && selected_wall_color_id !== 0) {
        const wall_data = await fetchingDataWithAxiosMiddleware(
          "GET",
          ServiceApi.get_wall_color(selected_wall_color_id)
        );
        if (wall_data?.data?.id) {
          app_state.common = {
            ...app_state.common,
            random_walls: [...app_state.common.random_walls, wall_data?.data],
          };
        }
      }

      if (!is_default_look_tile && selected_tile_color_id !== 0) {
        const tile_data = await fetchingDataWithAxiosMiddleware(
          "GET",
          ServiceApi.get_floor_tile(selected_tile_color_id)
        );
        if (tile_data?.data?.id) {
          app_state.common = {
            ...app_state.common,
            random_tiles: [...app_state.common.random_tiles, tile_data?.data],
          };
        }
      }

      const random_walls_data = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_random_walls_tiles_url(1, 20, "wall")
      );

      const random_tiles_data = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_random_walls_tiles_url(1, 20, "tile")
      );

      app_state.common = {
        ...app_state.common,
        next_random_tiles: random_tiles_data?.data?.next_page,
        next_random_walls: random_walls_data?.data?.next_page,
        random_walls: [
          ...app_state.common.random_walls,
          ...random_walls_data?.data?.data,
        ],
        random_tiles: [
          ...app_state.common.random_tiles,
          ...random_tiles_data?.data?.data,
        ],
      };
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong");
    }
  }

  async set_look_info(
    app_state: AppStateI,
    wall_id: string,
    tile_id: string,
    look_info: string
  ): Promise<void> {
    const look_data_response = await fetchingDataWithAxiosMiddleware(
      "GET",
      ServiceApi.get_look_info_url(look_info)
    );

    const is_default_wall_color: boolean =
      parseInt(wall_id) === parseInt(look_data_response?.data?.wallColor.id);
    const is_default_tile_type: boolean =
      parseInt(tile_id) === parseInt(look_data_response?.data?.floorTile.id);
    ServiceDataControl.default_look_info(look_data_response?.data);

    app_state.common = {
      ...app_state.common,
      look: {
        ...app_state.common.look,
        id: look_data_response.data.id,
        urlWithLogos: look_data_response.data.urlWithLogos,
      },
    };

    if (is_default_wall_color) {
      app_state.common = {
        ...app_state.common,
        look: {
          ...app_state.common.look,
          wallColor: {...look_data_response.data.wallColor},
        },
      };
    } else {
      await this.set_wall_info(app_state, wall_id);
    }

    if (is_default_tile_type) {
      app_state.common = {
        ...app_state.common,
        look: {
          ...app_state.common.look,
          floorTile: {...look_data_response.data.floorTile},
        },
      };
    } else {
      await this.set_tile_info(app_state, tile_id);
    }

    await this.set_random_walls_and_tiles(
      app_state,
      parseInt(look_data_response?.data?.wallColor.id),
      parseInt(look_data_response?.data?.floorTile.id),
      parseInt(wall_id),
      parseInt(tile_id)
    );
  }

  async set_spheres_logos_position(
    app_state: AppStateI,
    sphere_name: string
  ): Promise<void> {
    try {
      const logos_position = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_positions_of_logo(sphere_name)
      );
      app_state.common.logos_positions = {
        ...logos_position?.data?.logo_location,
      };
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong with sphere logos");
    }
  }

  async set_still_photos_logos_position(
    app_state: AppStateI,
    sphere_id: string
  ): Promise<void> {
    try {
      const response = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_apply_look_result(sphere_id)
      );
      app_state.common.still_photo_logo_location = {
        ...response.data?.logo_location,
      };
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong with still logos");
    }
  }

  async shared_third_page(
    page_param: UrlParamsI
  ): Promise<{ app_state: AppStateI; error_message: string }> {
    try {
      const page_information: PagesParamInfoI =
        this.handler_pages_param(page_param);

      const app_state: AppStateI = {
        common: {
          ...common_reducer_initial_state,
          look: {
            ...common_reducer_initial_state.look,
            url: page_information.look_url,
          },
          result: {
            sphere_endpoint: page_information.sphere_endpoint,
            still_photo_endpoint: page_information.still_photo_endpoint,
            old_sphere_url: page_information.old_sphere_url,
            new_sphere_url: page_information.new_sphere_url,
            old_still_photo_url: page_information.old_still_photo_url,
            new_still_photo_url: page_information.new_still_photo_url,
          },
          logos_positions: {
            rotate_sphere: [0, 0],
            walls_marker: [1.6780415086248932, -0.22584317164642664],
            floor_marker: [1.5466602241635017, -1.0929227440372014],
          },
        },
        config: {
          ...config_reducer_initial_state,
          look_uploaded: page_information.look_uploaded,
          is_still_photo: page_information.is_still_photo,
          is_shared_page: true,
          current_process_type: "shared page",
        },
      };

      await this.set_look_info(
        app_state,
        page_information.wall_id,
        page_information.tile_id,
        page_param.look_info
      );

      if (!app_state.config.is_still_photo) {
        await this.set_spheres_logos_position(
          app_state,
          page_param.sphere_info
        );
      }
      if (app_state.config.is_still_photo) {
        await this.set_still_photos_logos_position(
          app_state,
          page_param.sphere_id
        );
      }

      ServiceDataControl.save_configs(app_state.config, app_state.common);

      return {app_state, error_message: ""};
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong");
      return {
        app_state: {
          common: common_reducer_initial_state,
          config: config_reducer_initial_state,
        },
        error_message: "Error shared page",
      };
    }
  }

  cut_name(url: string): string {
    return url.slice(
      url.length - url.split("").reverse().join("").indexOf("/"),
      url.length - 4
    );
  }

  share_url_generator(
    look_url: string,
    new_sphere_url: string,
    is_still_photo: boolean,
    look_uploaded: boolean,
    sphere_id: number
  ): string {
    const look_name = this.cut_name(
      look_url
        ? look_url
        : ServiceDataControl.get_session_storage_item<string>(
        "saved_look_url"
      ) || ""
    );
    let sphere_name = this.cut_name(new_sphere_url);

    if (sphere_name.indexOf("_with_logo") > 0) {
      sphere_name = sphere_name.slice(0, sphere_name.indexOf("_with_logo"));
    }

    return `${ServiceApi.get_domain_name()}/shared/${
      look_uploaded ? "custom" : "hard"
    }/${look_name}/${is_still_photo ? "still" : "hard"}/${sphere_name}/${
      sphere_id || ServiceDataControl.get_session_storage_item("sphere_id")
    }`;
  }


  open_shared_page(data: FaceDataI, navigate: NavigateFunction) {
    const app_state: AppStateI = {
      common: {
        ...common_reducer_initial_state,
        look: {
          ...common_reducer_initial_state.look,
          ...data.look,
        },
        loading_time: {
          ...common_reducer_initial_state.loading_time,
          ...data.loading_time
        },
        result: {
          ...common_reducer_initial_state.result,
          ...data.result
        },
        logos_positions: {
          ...common_reducer_initial_state.logos_positions,
          ...data.logos_positions
        },
        uploaded_look_url: data.is_custom_look ? data.look.url : common_reducer_initial_state.uploaded_look_url,

      },
      config: {
        ...config_reducer_initial_state,
        look_uploaded: data.is_custom_look,
        is_still_photo: data.is_still_photo,
        modal_type: "",
        extra_modal_type: ""
      }
    }
    ServiceDataControl.default_look_info(data.look)
    ServiceDataControl.set_session_storage_item<string| number>("sphere_id", data.process_id)

    ServiceDataControl.save_configs(app_state.config, app_state.common)
    navigate("/")
  }

  share_with_process_id(process_id: number | string) {
    return `http://localhost:3010/shared/${process_id}`
    // return `${ServiceApi.get_domain_name()}/shared/${process_id}`
  }

}
