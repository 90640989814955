import markerStyle from "./marker.module.scss";
import {FC, useEffect, useState} from "react";
import {change_material_type} from "../../Redux/Actions";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../Redux/store";
import green_dote from "../../Assets/Images/Green_dote.png";
import tag_marker from "../../Assets/Images/marker.png";
import {typedSelector} from "../../Hooks/useTypedSelector";
import {handler_marker_text, material_name_cut_number, vendor_name_cut_number,} from "./markersConfig";

export interface MarkerPositionI {
  wall_marker: {
    top: number;
    left: number;
  };
  floor_marker: {
    top: number;
    left: number;
  };
}

const starting_position_of_marker_drawing_bottom: number = 0;

const StillMarker: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {material_type, sphere_statuses} = typedSelector(
    (state) => state.config
  );
  const {look, still_photo_logo_location} = typedSelector(
    (state) => state.common
  );
  const [position, setPosition] = useState<MarkerPositionI>({
    wall_marker: {
      top: 20,
      left: 20,
    },
    floor_marker: {
      top: 30,
      left: 30,
    },
  });

  const marker_container = document.querySelector(
    `.${markerStyle.markers_container}`
  );


  const handleWallVendor = () => {
    dispatch(change_material_type("wall"));
    if (material_type === "wall") {
      window.open(look?.wallColor.urlOnVendorPage, "_blank");
    }
  };

  const handleTileVendor = () => {
    dispatch(change_material_type("tile"));
    if (material_type === "tile") {
      window.open(look?.floorTile.urlOnVendorPage, "_blank");
    }
  };

  useEffect(() => {
    if (!!marker_container) {
      const wall_top_pos: number =
          (still_photo_logo_location.wall_marker.top *
            marker_container.clientHeight) /
          100,
        wall_left_pos: number =
          (still_photo_logo_location.wall_marker.left *
            marker_container.clientWidth) /
          100,
        floor_top_pos: number =
          (still_photo_logo_location.floor_marker.top *
            marker_container.clientHeight) /
          100,
        floor_left_pos: number =
          (still_photo_logo_location.floor_marker.left *
            marker_container.clientWidth) /
          100;

      const get_position = (type: string, current_position: number, is_wall: boolean): number => {
        const wall_wrapper = document.getElementById("wall_wrapper")
        const floor_wrapper = document.getElementById("floor_wrapper")
        // const container_width:number = (is_wall ? wall_wrapper?.clientWidth : floor_wrapper?.clientWidth)  || 70
        const container_width: number = 0
        if (type === "top" && !is_wall) {
          // console.log("<--------------------------- CURRENT POSITION", current_position, marker_container.clientHeight)
          // console.log("<> DELTA POSITION", current_position + 70, marker_container.clientHeight)
        }

        let position: number = 0;
        const height =
            marker_container.clientHeight -
            starting_position_of_marker_drawing_bottom,
          width: number = marker_container.clientWidth - container_width
        if (type === "top") {
          position = current_position > height ? height : current_position;
        }
        if (type === "left") {
          position = current_position > width ? width : current_position;
        }
        return position;
      };

      setPosition({
        wall_marker: {
          top: get_position("top", wall_top_pos, true),
          left: get_position("left", wall_left_pos, true),
        },
        floor_marker: {
          top: get_position("top", floor_top_pos, false),
          left: get_position("left", floor_left_pos, false),
        },
      });
    }
  }, [marker_container?.clientHeight, still_photo_logo_location]);


  const sizeFloorSizeChecker = (marker_container: any, position: any) => {
    if (!marker_container) return
    let floor_left_pos = position.floor_marker.left, floor_top_pos = position.floor_marker.top;


    if (
      marker_container.clientWidth < floor_left_pos + 150 &&
      marker_container.clientHeight < floor_top_pos + 55
    ) {
      let right = marker_container.clientWidth - floor_left_pos
      const bottom = marker_container.clientHeight - floor_top_pos
      return {
        bottom,
        right,
      }
    }


    if (marker_container.clientHeight < floor_top_pos + 55) {
      const bottom = marker_container.clientHeight - floor_top_pos
      return {
        bottom,
        left: `${floor_left_pos}px`,
      }
    }


    if (
      marker_container.clientWidth < floor_left_pos + 150
    ) {
      console.log(floor_left_pos, floor_top_pos, "delta", floor_left_pos + 150, marker_container.clientWidth)

      let right = marker_container.clientWidth - floor_left_pos
      return {
        top: `${floor_top_pos}px`,
        right,
      }
    }


    return {
      top: `${floor_top_pos}px`,
      left: `${floor_left_pos}px`,
    }
  }

  const floorWrapperClassName = (sizeFloorSizeChecker(marker_container, position)?.bottom && sizeFloorSizeChecker(marker_container, position)?.right) ?
    markerStyle.floor_wrapper_4 : (!sizeFloorSizeChecker(marker_container, position)?.bottom && sizeFloorSizeChecker(marker_container, position)?.right) ?
      markerStyle.floor_wrapper_3 : (sizeFloorSizeChecker(marker_container, position)?.bottom && !sizeFloorSizeChecker(marker_container, position)?.right) ?
        markerStyle.floor_wrapper_2 : markerStyle.floor_wrapper;

  return (
    <div
      className={markerStyle.markers_container}
      style={sphere_statuses.new_sphere ? {zIndex: "0"} : {zIndex: -1}}
    >
      <div
        id="wall_wrapper"
        onClick={() => handleWallVendor()}
        className={markerStyle.wall_wrapper}
        style={{
          top: `${position.wall_marker.top}px`,
          left: `${position.wall_marker.left}px`,
        }}
      >
        <div className={markerStyle.green_dote}>
          <img src={green_dote} alt="Green dote"/>
        </div>
        <div className={markerStyle.marker_info_wrapper}>
          {
            !!position.wall_marker ?  <div className={markerStyle.info}>
              <h4
                className={markerStyle.material_name}
                style={
                  material_type === "wall"
                    ? {fontWeight: 800}
                    : {fontWeight: 400}
                }
              >
                {handler_marker_text(
                  material_name_cut_number,
                  look?.wallColor?.id !== 0 ? look?.wallColor?.name : "None"
                )}
              </h4>
              {look.wallColor.id !== 0 ? (
                <h4
                  className={markerStyle.material_vendor_name}
                  // style={material_type === "wall" ? {fontWeight: 800} : {fontWeight: 400}}
                >
                  {"vendorName" in look.wallColor
                    ? `by ${handler_marker_text(
                      vendor_name_cut_number,
                      look.wallColor.vendorName
                    )}`
                    : ""}
                </h4>
              ) : (
                <></>
              )}
            </div> : <></>
          }
          {material_type === "wall" && look.wallColor.id !== 0 ? (
            <div className={markerStyle.tag}>
              <img src={tag_marker} alt="tag marker"/>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {
        !!position.floor_marker ? <div
            id="floor_wrapper"
            onClick={() => handleTileVendor()}
            className={floorWrapperClassName}
            style={sizeFloorSizeChecker(marker_container, position)}
          >
            <div className={markerStyle.green_dote}>
              <img src={green_dote} alt="Green dote"/>
            </div>
            <div className={markerStyle.marker_info_wrapper}>
              <div className={markerStyle.info}>
                <h4
                  className={markerStyle.material_name}
                  style={
                    material_type === "tile"
                      ? {fontWeight: 800}
                      : {fontWeight: 400}
                  }
                >
                  {handler_marker_text(
                    material_name_cut_number,
                    look.floorTile.id !== 0 ? look?.floorTile?.name : "None"
                  )}
                </h4>
                {look.floorTile.id !== 0 ? (
                  <h4 className={markerStyle.material_vendor_name}>
                    {"vendorName" in look.floorTile
                      ? `by ${handler_marker_text(
                        vendor_name_cut_number,
                        look.floorTile.vendorName
                      )}`
                      : ""}
                  </h4>
                ) : (
                  <></>
                )}
              </div>
              {material_type === "tile" && look.floorTile.id !== 0 ? (
                <div className={markerStyle.tag}>
                  <img src={tag_marker} alt="tag marker"/>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          : <></>
      }
    </div>
  );
};

export default StillMarker;
