import React, { FC, useEffect, useState, useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import footerStyle from "./footer.module.scss";
import View360 from "../Modals/View360/view360";

import {
  change_extra_modal_state,
  change_modal,
  set_common_reducer_state,
  set_config_reducer_state,
} from "../../Redux/Actions";
import { AppDispatch } from "../../Redux/store";
import { typedSelector } from "../../Hooks/useTypedSelector";
import { useAsyncActions } from "../../Hooks/useActions";
import { FooterI } from "../../Types";
import logo from "../../Assets/Images/logo.svg";
import logo_white from "../../Assets/Images/logo_white.svg";
import { ServiceDataControl } from "../../Services";
import {
  common_reducer_initial_state,
  config_reducer_initial_state,
} from "../../Common/Config/reducersConfig";

const Footer: FC<FooterI> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const { get_user_ip } = useAsyncActions();
  const id = uuidv4();

  const [isMobile, setIsMobile] = useState(false);

  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);
  const { result_checker_state, upload_look_checker_state } = common;

  useEffect(() => {
    window.sessionStorage.setItem("isReloaded", JSON.stringify(true));

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      navigate("/");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleStorage = () => {
    dispatch(set_common_reducer_state(common_reducer_initial_state));
    dispatch(set_config_reducer_state(config_reducer_initial_state));
    dispatch(change_extra_modal_state());
    sessionStorage.setItem(
      "common",
      JSON.stringify(common_reducer_initial_state)
    );
    sessionStorage.setItem(
      "config",
      JSON.stringify(config_reducer_initial_state)
    );
    dispatch(change_modal("Inspiration"));
  };

  useEffect(() => {
    get_user_ip();
  }, []);

  useEffect(() => {
    if (
      result_checker_state.called_id.ID ||
      upload_look_checker_state.called_id.ID
    ) {
      ServiceDataControl.save_configs(config, common);
    }
  }, [
    result_checker_state.called_id.ID,
    upload_look_checker_state.called_id.ID,
  ]);

  useEffect(() => {
    ServiceDataControl.check_page(dispatch);
    if (!JSON.parse(String(sessionStorage.getItem("SessionID")))) {
      sessionStorage.setItem("SessionID", JSON.stringify(id));
    }
  }, []);

  useEffect(() => {
    if (common.look.url) {
      ServiceDataControl.save_configs(config, common);
    }
  }, [common.look.url]);

  const memorized_360_page = useMemo(() => {
    return <View360 />;
  }, []);

  return (
    <div className={footerStyle.footer}>
      <div className={footerStyle.footer_box} onClick={handleStorage}>
        <p className={props.classname}>Powered by</p>
        <img
          className={props.classname}
          src={isMobile ? logo : logo_white}
          alt="logo"
        />
      </div>
      {memorized_360_page}
    </div>
  );
};

export default Footer;
