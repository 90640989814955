import React, {FC, useCallback} from "react";
import stillPhotoStyle from "./stillPhoto.module.scss";
import {
  change_showed_sphere,
  clear_loading_time,
  set_new_spheres_status,
  set_old_spheres_status
} from "../../../Redux/Actions";
import view_new from "../../../Assets/Images/view_new.svg";
import view_old from "../../../Assets/Images/view_old.svg";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../Redux/store";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import toast from "react-hot-toast";
import share_vector from "../../../Assets/Vectors/share.svg";
import {ServiceDataControl, ServicePagesControl} from "../../../Services";


export const ChangeShowedSphere: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);
  const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);


  return <div
    className={stillPhotoStyle.new_old_clickable_area}
    onClick={() => {
      dispatch(change_showed_sphere());
    }}
  >
    {sphere_statuses.new_sphere &&
      (is_new_sphere ? (
        <img
          className={stillPhotoStyle.view_old}
          src={view_new}
          alt="view old sphere"
        />
      ) : (
        <img
          className={stillPhotoStyle.view_new}
          src={view_old}
          alt="view new sphere"
        />
      ))}
  </div>
}


export const ShareButton: FC = () => {
  const look = typedSelector((state) => state.common.look);
  const result_checker_state = typedSelector((state) => state.common.result_checker_state);
  const result = typedSelector((state) => state.common.result);
  const is_still_photo = typedSelector((state) => state.config.is_still_photo);
  const look_uploaded = typedSelector((state) => state.config.look_uploaded);
  const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);
  const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);

  const copied_url: () => string = useCallback(() => {
    return ServicePagesControl.share_url_generator(
        look.url,
        is_still_photo ? result.new_still_photo_url : result.new_sphere_url,
        is_still_photo,
        look_uploaded,
        result_checker_state.called_id.ID || 0
    );
    // return ServicePagesControl.share_with_process_id(ServiceDataControl.get_session_storage_item<string | number>("sphere_id"))
  }, [result_checker_state.called_id.ID]);

  if (sphere_statuses.new_sphere && is_new_sphere) {
    return <img
      className={stillPhotoStyle.share_button}
      onClick={() => {
        if (navigator?.share) {
          navigator
            .share({
              title: "Check out this look in my space!",
              url: copied_url()
            })
            .then(() => console.log("Success"))
            .catch(() => console.log("Canceled"));
        } else {
          console.warn(
            "Your browser doesn't have native share"
          );
        }
        if (
          navigator?.clipboard &&
          navigator?.clipboard?.writeText
        ) {
          navigator.clipboard.writeText(copied_url()).then(() => {
            toast("Link copied to clipboard");
          });
        } else {
          console.warn("Please update your browser!!");
        }
      }}
      src={share_vector}
      alt="share"
    />
  }


  return <></>
}


export const OldLookStyle: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const old_still_photo_url = typedSelector(state => state.common.result.old_still_photo_url)
  const is_new_sphere = typedSelector(state => state.config.is_new_sphere)


  return <div
    className={
      is_new_sphere
        ? `${stillPhotoStyle.old_still_photo_wrapper} ${stillPhotoStyle.hide}`
        : `${stillPhotoStyle.old_still_photo_wrapper} ${stillPhotoStyle.show}`
    }
  >
    <img
      src={old_still_photo_url}
      alt="Old result"
      onLoad={() => dispatch(set_old_spheres_status(true))}
    />
    {/*{!sphere_statuses.old_sphere && <ViewerLoading sphere_type="old" />}*/}
  </div>
}

export const NewLookStyle: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const new_still_photo_url = typedSelector(state => state.common.result.new_still_photo_url)

  if (!new_still_photo_url) {
    return <></>
  }

  return <img
    src={new_still_photo_url}
    alt="New result"
    onLoad={() => {
      dispatch(set_new_spheres_status(true));
      dispatch(clear_loading_time());
    }}
  />
}