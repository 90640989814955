import {SpheresPositionsI} from "../../../Types";
import React, {FC, useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../Redux/store";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {GyroscopePlugin, MarkersPlugin, ReactPhotoSphereViewer, StereoPlugin} from "react-photo-sphere-viewer";
import resultStyle from "./sphereResult.module.scss";
import {change_marker} from "../../../Common/Config";
import {Viewer} from "@photo-sphere-viewer/core";
import {
    change_extra_modal_state,
    change_loading, change_modal,
    clear_loading_time,
    is_clicked,
    set_new_spheres_status
} from "../../../Redux/Actions";
import {green_dots_and_tag_markers_functional} from "./photosphereViewerConfig";
import {ServiceDataControl} from "../../../Services";
import useViewerMarkers from "./Hooks/useViewerMarkers";

interface NewStyleViewerI {
    old_photoSphereRef: any,
    new_photoSphereRef: any,
    spheres_position: SpheresPositionsI
}

const NewStyleViewer: FC<NewStyleViewerI> = ({
                                                 old_photoSphereRef,
                                                 new_photoSphereRef,
                                                 spheres_position
                                             }) => {
    const dispatch = useDispatch<AppDispatch>();
    const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);
    const material_type = typedSelector((state) => state.config.material_type);

    const logos_positions = typedSelector((state) => state.common.logos_positions);
    const look = typedSelector((state) => state.common.look);
    const result = typedSelector((state) => state.common.result);


    useViewerMarkers(new_photoSphereRef, old_photoSphereRef);

    // SET NEW PANORAMA & AUTOROTATE
    useEffect(() => {
        if (!new_photoSphereRef.current) return;
        if (result.new_sphere_url) {
            dispatch(set_new_spheres_status());
            const animate = {
                yaw: logos_positions.rotate_sphere[0],
                pitch: logos_positions.rotate_sphere[1]
            };
            new_photoSphereRef.current
                //@ts-ignore
                .setPanorama(result.new_sphere_url)
                ?.then(() => {
                    //@ts-ignore
                    new_photoSphereRef?.current?.rotate(animate);
                    //@ts-ignore
                    const markers = new_photoSphereRef.current?.getPlugin("markers");
                    markers?.updateMarker(
                        change_marker(
                            "wall",
                            look?.wallColor,
                            logos_positions,
                            material_type
                        )
                    );
                    markers?.updateMarker(
                        change_marker(
                            "tile",
                            look?.floorTile,
                            logos_positions,
                            material_type
                        )
                    );
                })
                ?.then(() => {
                    const autorotate_div = document.getElementById("autorotate");
                    if (autorotate_div) {
                        autorotate_div.style.display = "block";
                    }
                    green_dots_and_tag_markers_functional(dispatch, look, material_type);
                    const autorotate_plugin =
                        //@ts-ignore
                        new_photoSphereRef?.current.getPlugin("autorotate");
                    //@ts-ignore
                    autorotate_plugin.start();
                    autorotate_plugin.config.autorotateSpeed = 0.09; // 0.2093
                    autorotate_plugin.config.autorotatePitch = 0.04; // 0.0872
                    autorotate_plugin.config.autorotateZoomLvl = 0.1; // null
                    dispatch(set_new_spheres_status(true));
                    dispatch(clear_loading_time());
                })
                .catch(() => {
                    dispatch(change_loading(false));
                    ServiceDataControl.get_last_viewed_style(dispatch);
                    ServiceDataControl.get_steps(dispatch);
                    dispatch(is_clicked(false));
                    dispatch(change_extra_modal_state());
                    dispatch(change_modal());
                    ServiceDataControl.get_last_viewed_style(dispatch);
                    // toast.error("Something went wrong, please try again 333");
                    dispatch(clear_loading_time());
                });
        }
    }, [result.new_sphere_url]);


    const new_sphere = useMemo(() => {
        if (result?.new_sphere_url) {
            return (
                <ReactPhotoSphereViewer
                    ref={new_photoSphereRef}
                    src={result.new_sphere_url}
                    height="100%"
                    width="100%"
                    container={""}
                    navbar={false}
                    containerClass={resultStyle.new_sphere}
                    defaultYaw={spheres_position.yaw}
                    defaultPitch={spheres_position.pitch}
                    defaultZoomLvl={0.1}
                    keyboard={'always'}
                    // touchmoveTwoFingers={true}
                    plugins={[
                        [
                            GyroscopePlugin,
                            {
                                touchmove: false,
                                absolutePosition: false,
                                moveMode: "smooth"
                            }
                        ],
                        StereoPlugin,
                        [
                            MarkersPlugin,
                            {
                                markers: [
                                    change_marker(
                                        "wall",
                                        look?.wallColor,
                                        logos_positions,
                                        material_type
                                    ),
                                    change_marker(
                                        "tile",
                                        look?.floorTile,
                                        logos_positions,
                                        material_type
                                    )
                                ]
                            }
                        ]
                    ]}
                    onZoomChange={({zoomLevel}) => {
                        if (!!old_photoSphereRef.current) {
                            //@ts-ignore
                            old_photoSphereRef.current.animate({zoom: zoomLevel})
                        }
                    }}
                    onPositionChange={(_lat: number, _lng: number, instance: Viewer) => {
                        const autorotate_plugin =
                            //@ts-ignore
                            new_photoSphereRef?.current?.getPlugin("autorotate");
                        // //@ts-ignore
                        // autorotate_plugin.start();
                        const positions = instance.getPosition();
                        if (!!old_photoSphereRef.current && !autorotate_plugin?.state?.enabled) {
                            //@ts-ignore
                            old_photoSphereRef.current?.rotate({
                                pitch: positions.pitch,
                                yaw: positions.yaw
                            })
                        }
                    }}
                    // loadingTxt={""}
                    onReady={() => {
                        dispatch(set_new_spheres_status(true));
                    }}
                />
            );
        }
    }, [
        result?.new_sphere_url,
        new_photoSphereRef
    ]);


    return <div
        className={
            is_new_sphere
                ? `${resultStyle.new_sphere} ${resultStyle.active_viewer_new}`
                : resultStyle.new_sphere
        }
    >
        {new_sphere}
    </div>
}

export default NewStyleViewer