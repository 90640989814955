import React, { ChangeEvent, FC, useMemo, useState } from "react";
import wallsAndTileStyles from "./wallsAndTiles.module.scss";
import { useAsyncActions } from "../../Hooks/useActions";
import { typedSelector } from "../../Hooks/useTypedSelector";
import Circles from "./Circles/circles";
import SphereMaterial from "./SphereMaterial/sphereMaterial";
import { change_modal, set_more_text } from "../../Redux/Actions";
import { AppDispatch } from "../../Redux/store";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import VendorsInfo from "./vendorsInfo";
import Email from "../Modals/Email/email";
import modalStyle from "../../Common/Modal/modal.module.scss";
import Modal from "../../Common/Modal/modal";

const WallsAndTiles: FC = () => {
  const [cookie, setCookie] = useCookies(["email"]);

  const dispatch = useDispatch<AppDispatch>();
  const { text_user_form } = useAsyncActions();
  const { material_type, more_text, modal_type, sphere_statuses } =
    typedSelector((state) => state.config);

  const [text, setText] = useState<string>("");

  const memo_email = useMemo(() => {
    return (is_still = false) => {
      return (
        !cookie.email && (
          <Modal
            arrow_back_button={false}
            title_text_h1={""}
            title_text_h2={""}
            context={<Email is_still={is_still} message={text} />}
            onClose={() => dispatch(change_modal())}
            classname={modalStyle.extra_email}
            close={true}
          />
        )
      );
    };
  }, [text]);

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(set_more_text(e.target.value));
    setText(e.target.value);
  };

  const handleSubmit = () => {
    if (!cookie.email) {
      dispatch(change_modal("Email more"));
    } else {
      text_user_form(
        {
          email: cookie.email,
          message: text,
          errorMessage: "",
        },
        setCookie
      );
      setText("");
    }
  };

  return (
    <div className={wallsAndTileStyles.main}>
      <SphereMaterial />
      <div className={wallsAndTileStyles.wrapper}>
        {material_type === "more" ? (
          <>
            <div className={wallsAndTileStyles.more_box}>
              <div className={wallsAndTileStyles.more_text_style}>
                <p>You decide what rolls out next. </p>
                <p>Tell us what features you want most!</p>
              </div>
              <textarea
                rows={4}
                value={text}
                className={wallsAndTileStyles.more_info}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleChangeText(e)
                }
              />
            </div>
            {!more_text ? (
              <div className={wallsAndTileStyles.more_button_disable}>
                <p>Submit</p>
              </div>
            ) : (
              <div
                className={wallsAndTileStyles.more_button}
                onClick={() => handleSubmit()}
              >
                <p>Submit</p>
              </div>
            )}
          </>
        ) : (
          <div className={wallsAndTileStyles.wallFloors}>
            <Circles material_type={material_type} />
            <VendorsInfo />
          </div>
        )}

        {modal_type === "Email more" ? memo_email() : <></>}

        {!sphere_statuses.new_sphere && (
          <div className={wallsAndTileStyles.disabled_block} />
        )}
      </div>
    </div>
  );
};

export default WallsAndTiles;
