import { AppDispatch } from "../Redux/store";
import {
  ConfigInitialStateI,
  LastViewedStyleI,
  LookDataI,
  RCommonStateI,
  RConfigStateI,
  StepsI,
} from "../Types";
import {
  change_extra_modal_state,
  change_modal,
  clear_loading_time,
  clear_result_checker,
  clear_result_data,
  set_360_page_config,
  set_common_reducer_state,
  set_config_reducer_state,
  set_full_screen_state,
  set_look,
  set_new_spheres_status,
  set_old_spheres_status,
  set_result_data,
} from "../Redux/Actions";
import {
  common_reducer_initial_state,
  config_reducer_initial_state,
} from "../Common/Config/reducersConfig";
import {
  clear_inspiration_image_base_64,
  clear_still_photo_base_64,
} from "../Redux/Actions/CommonActions/assetsAction";

export class DataControl {
  set_session_storage_item<T>(item_name: string, payload: T): void {
    sessionStorage.setItem(item_name, JSON.stringify(payload));
  }

  get_session_storage_item<T>(item_name: string): T {
    return JSON.parse(String(sessionStorage.getItem(item_name)));
  }

  remove_session_storage_item(item_name: string): void {
    sessionStorage.removeItem(item_name);
  }

  default_look_info(look_data: LookDataI | null): void | LookDataI {
    if (look_data?.url) {
      this.set_session_storage_item<LookDataI>("default_look", look_data);
    }
    if (look_data === null) {
      return this.get_session_storage_item<LookDataI>("default_look");
    }
  }

  save_configs(config: ConfigInitialStateI, common: RCommonStateI): void {
    this.set_session_storage_item<ConfigInitialStateI>("config", config);
    this.set_session_storage_item<RCommonStateI>("common", common);
  }

  change_page_settings(isReloaded?: boolean): void {
    if (isReloaded) {
      this.set_session_storage_item<boolean>("isReloaded", true);
    }
    if (!isReloaded) {
      this.set_session_storage_item<boolean>("isReloaded", false);
    }
  }

  get_saved_data(dispatch: AppDispatch): void {
    const config = this.get_session_storage_item<RConfigStateI>("config");
    const common = this.get_session_storage_item<RCommonStateI>("common");
    dispatch(
      set_config_reducer_state({ ...config, is_full_screen_enabled: false })
    );
    dispatch(set_common_reducer_state(common));
    if (common?.result.new_sphere_url || common?.result.new_still_photo_url) {
      dispatch(change_modal());
      dispatch(change_extra_modal_state());
      dispatch(set_360_page_config(false));
    }
    if (!common?.result.new_sphere_url && !common?.result.new_still_photo_url) {
      dispatch(change_modal("Inspiration"));
      dispatch(set_360_page_config(false));
    }
  }

  check_page(dispatch: AppDispatch): void {
    if (this.get_session_storage_item<boolean>("isReloaded")) {
      this.change_page_settings(false);
      this.get_saved_data(dispatch);
    }
  }

  get_default_look(
    dispatch: AppDispatch,
    look_uploaded: boolean,
    look: LookDataI
  ): void {
    dispatch(clear_result_checker());
    dispatch(clear_result_data());
    if (
      !look.url &&
      !look_uploaded &&
      this.get_session_storage_item<LookDataI>("default_look")?.url
    ) {
      dispatch(
        set_look(this.get_session_storage_item<LookDataI>("default_look"))
      );
    }
    if (this.get_session_storage_item<LookDataI>("default_look") !== null) {
      if (
        look?.wallColor.id !==
          this.get_session_storage_item<LookDataI>("default_look")?.wallColor
            ?.id ||
        look?.floorTile.id !==
          this.get_session_storage_item<LookDataI>("default_look")?.floorTile
            ?.id
      ) {
        dispatch(
          set_look(this.get_session_storage_item<LookDataI>("default_look"))
        );
      }
    }
  }

  set_last_viewed_style(payload: LastViewedStyleI): void {
    this.set_session_storage_item<LastViewedStyleI>(
      "last_viewed_style",
      payload
    );
  }

  get_last_viewed_style(dispatch: AppDispatch): void {
    const data =
      this.get_session_storage_item<LastViewedStyleI>("last_viewed_style");
    if (data?.result?.new_sphere_url || data?.result?.new_still_photo_url) {
      dispatch(clear_still_photo_base_64());
      dispatch(clear_inspiration_image_base_64());
      dispatch(set_look(data.look));
      dispatch(set_result_data(data.result));
      dispatch(set_config_reducer_state(data.config));
      dispatch(set_new_spheres_status(true));
      dispatch(clear_loading_time());
      dispatch(set_old_spheres_status(true));
      dispatch(change_modal());
      dispatch(change_extra_modal_state());
      dispatch(set_full_screen_state());
    } else {
      dispatch(set_common_reducer_state(common_reducer_initial_state));
      dispatch(set_config_reducer_state(config_reducer_initial_state));
      dispatch(change_modal("Inspiration"));
      dispatch(change_extra_modal_state());
      dispatch(set_full_screen_state());
    }
  }

  set_last_selected_wall_and_tile(wall_id: number, tile_id: number): void {
    this.set_session_storage_item<{ wall_id: number; tile_id: number }>(
      "last_wall_tile_ids",
      { wall_id, tile_id }
    );
  }

  set_steps(payload: StepsI): void {
    this.set_session_storage_item<StepsI>("steps", payload);
  }

  get_steps(dispatch: AppDispatch): void {
    const data = this.get_session_storage_item<StepsI>("steps");
    if (data?.result?.new_sphere_url || data?.result?.new_still_photo_url) {
      dispatch(clear_still_photo_base_64());
      dispatch(clear_inspiration_image_base_64());
      dispatch(set_look(data.look));
      dispatch(set_result_data(data.result));
      dispatch(set_config_reducer_state(data.config));
      dispatch(set_new_spheres_status(true));
      dispatch(clear_loading_time());
      dispatch(set_old_spheres_status(true));
      dispatch(change_modal());
      dispatch(change_extra_modal_state());
    } else {
      dispatch(set_common_reducer_state(common_reducer_initial_state));
      dispatch(set_config_reducer_state(config_reducer_initial_state));
      dispatch(change_modal("Inspiration"));
      dispatch(change_extra_modal_state());
    }
  }
}
