import FormData from "form-data";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store";
import { ServiceApi, ServiceDataControl } from "../../../Services";
import { fetchingDataWithAxiosMiddleware } from "./commonAction";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  change_reminder,
  is_clicked,
  set_current_process_type,
  set_still_photo_type,
} from "../CommonActions/configAction";
import {
  set_old_still_photo_url,
  set_still_photo_endpoint,
} from "../CommonActions/stillPhotoAction";
import {
  clear_loading_time,
  clear_result_data,
} from "../CommonActions/commonAction";
import {
  ChangeWallORTileI,
  RCommonStateI,
  RConfigStateI,
} from "../../../Types";
import {
  set_new_spheres_status,
  set_old_spheres_status,
} from "../CommonActions/sphereAction";

export const upload_still_photo =
  (
    file: File,
    change_wall_or_tile_new: (
      payload: ChangeWallORTileI
    ) => (dispatch: AppDispatch) => Promise<void>,
    common: RCommonStateI,
    config: RConfigStateI
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(set_current_process_type("still photo"));
      ServiceDataControl.set_last_viewed_style({
        look: common.look,
        result: common.result,
        config,
      });
      dispatch(clear_loading_time());
      dispatch(set_new_spheres_status());
      dispatch(set_old_spheres_status());
      dispatch(change_extra_modal_state());
      dispatch(change_modal());
      dispatch(clear_result_data());
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.get_upload_still_photo_url(),
        formData
      );
      // throw new Error("Hunt's error");

      if (response.status) {
        const data: string = await response.data;
        dispatch(change_reminder(false));
        dispatch(set_still_photo_endpoint(data.substring(0, data.length - 4)));
        dispatch(
          set_old_still_photo_url(ServiceApi.read_old_still_photo_url(data))
        );
        dispatch(set_still_photo_type(true));
        change_wall_or_tile_new({
          wall: common?.look?.wallColor,
          tile: common?.look?.floorTile,
          sphere_endpoint: data.substring(0, data.length - 4),
          process_type: "stillphoto",
          interactionSource: config.look_uploaded
            ? "customLook"
            : "hardcodedLook",
          common,
          config,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error(
        error.message || "Something went wrong still photo uploading"
      );
      ServiceDataControl.get_last_viewed_style(dispatch);
      dispatch(is_clicked(false));
      dispatch(change_loading(false));
      dispatch(set_current_process_type(""));
    }
  };
