import { FC, useEffect } from "react";
import mainStyle from "./list.module.scss";
import Footer from "../Footer/footer";
import SphereHeader from "../Sphere/SphereHeader/sphereHeader";
import Tutorial from "../Sphere/Tutorial/tutorial";
import SphereBids from "../Sphere/SphereBids/sphereBids";
import { ModalsList } from "../../Common/ModalsList/modalsList";
import ExtraModal from "../../Common/ModalsList/extraModal";
import PrivacyPolicy from "../Footer/privacyPolicy";
import ResultStyles from "../ResultStyles/resultStyles";

const List: FC = () => {
  useEffect(() => {
    const handlePopstate = () => {
      if (
        window.confirm(
          "Are you sure you want to leave this page and lose your progress?"
        )
      ) {
        window.open("about:blank", "_self");
        window.close();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <div className={mainStyle.container}>
      <div className={mainStyle.block}>
        <SphereHeader />
        <ResultStyles />
        <Tutorial />
        <SphereBids />
        <Footer />
        <PrivacyPolicy />
        <ModalsList />
        <ExtraModal />
      </div>
    </div>
  );
};
export default List;
