import { AnyAction } from "redux";
import { RConfigStateI } from "../../Types";
import { config_reducer_initial_state } from "../../Common/Config/reducersConfig";
import {
  CAN_SHOW_360_PAGE,
  CHANGE_FULL_SCREEN_STATE,
  CHANGE_MATERIAL_TYPE,
  CHANGE_SHOWED_SPHERE,
  CLOSE_MODAL,
  CURRENT_PROCESS_TYPE,
  EXTRA_MODAL,
  FULL_SCREEN_STATE,
  GET_QUOTES_MODAL,
  IS_CLICKED,
  IS_DESKTOP,
  IS_REMINDER,
  IS_SECOND_PAGE_START,
  LOAD_TIME,
  LOADING,
  MORE_TEXT,
  NEW_SPHERE_STATUSES,
  OLD_SPHERE_STATUSES,
  OPEN_MODAL,
  OPEN_UPLOAD,
  RESET_CONFIG,
  SET_CONFIG_REDUCER_STATE,
  SET_COUNT,
  SET_LOOK_TYPE,
  SET_NAME,
  SET_RANDOM,
  SET_SPHERE_TYPE,
  SET_STILL_PHOTO_TYPE,
  SHARED_PAGE,
  THANKS_TEXT,
} from "../Actions";

const initialState: RConfigStateI = config_reducer_initial_state;

export const configReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CONFIG_REDUCER_STATE:
      return { ...state, ...action.payload };
    case IS_SECOND_PAGE_START:
      return { ...state, is_second_page_start: action.payload };
    case SET_LOOK_TYPE:
      return {
        ...state,
        look_uploaded: action.payload,
      };
    case SET_COUNT:
      return { ...state, count: action.payload };
    case SET_NAME:
      return { ...state, name: action.payload };
    case IS_REMINDER:
      return { ...state, is_reminder: action.payload };
    case SET_SPHERE_TYPE:
      return {
        ...state,
        sphere_uploaded: action.payload,
        is_still_photo: false,
      };
    case SET_RANDOM:
      return { ...state, is_random: action.payload };
    case SET_STILL_PHOTO_TYPE:
      return {
        ...state,
        is_still_photo: action.payload,
        sphere_uploaded: false,
      };
    case MORE_TEXT:
      return { ...state, more_text: action.payload };
    case CHANGE_SHOWED_SPHERE:
      return {
        ...state,
        is_new_sphere:
          action.payload === undefined ? !state.is_new_sphere : action.payload,
      };
    case LOADING:
      return { ...state, loading: action.payload };
    case GET_QUOTES_MODAL:
      return { ...state, quotes_modal_is_open: action.payload };
    case OPEN_MODAL:
      return { ...state, modal_type: action.payload };
    case CLOSE_MODAL:
      return { ...state, close_modal_type: action.payload };
    case LOAD_TIME:
      return { ...state, load_time: action.payload };
    case RESET_CONFIG:
      return { ...config_reducer_initial_state };
    case THANKS_TEXT:
      return { ...state, text_thanks: action.payload };
    case OPEN_UPLOAD:
      return { ...state, upload_modal: action.payload };
    case CHANGE_MATERIAL_TYPE:
      return { ...state, material_type: action.payload };
    case EXTRA_MODAL:
      return { ...state, extra_modal_type: action.payload };
    case SHARED_PAGE:
      return { ...state, is_shared_page: action.payload };
    case NEW_SPHERE_STATUSES:
      return {
        ...state,
        sphere_statuses: {
          ...state.sphere_statuses,
          new_sphere: action.payload,
        },
      };
    case OLD_SPHERE_STATUSES:
      return {
        ...state,
        sphere_statuses: {
          ...state.sphere_statuses,
          old_sphere: action.payload,
        },
      };
    case CURRENT_PROCESS_TYPE:
      return { ...state, current_process_type: action.payload };
    case IS_DESKTOP:
      return { ...state, is_desktop_use: action.payload };
    case IS_CLICKED:
      return { ...state, clicked: action.payload };
    case CAN_SHOW_360_PAGE:
      return { ...state, can_show_360_page: action.payload };
    case FULL_SCREEN_STATE:
      return { ...state, is_full_screen_enabled: action.payload };
    case CHANGE_FULL_SCREEN_STATE:
      return {
        ...state,
        is_full_screen_enabled: !state.is_full_screen_enabled,
      };
    default:
      return state;
  }
};
