import { LookDataI } from "../Types";
import { AppDispatch } from "../Redux/store";
import { set_look, set_look_type } from "../Redux/Actions";
import { ServiceDataControl } from "../Services";

export class LookController {
  select_look = (look: LookDataI, dispatch: AppDispatch): void => {
    dispatch(set_look_type(false));
    dispatch(set_look(look));
    ServiceDataControl.default_look_info(look);
  };
}
