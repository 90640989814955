import { NavigateFunction } from "react-router";

export class ConfigControl {
  protected password = "readysetgo";

  check_password(userPassword: string | undefined): boolean {
    if (userPassword === this.password) {
      localStorage.setItem("token", "authorized");
      return true;
    }
    return false;
  }
}
