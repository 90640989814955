import { useEffect, useState } from "react";
import { GyroscopePlugin } from "react-photo-sphere-viewer";
import { typedSelector } from "../../../../Hooks/useTypedSelector";

const useGyroscope = (new_photoSphereRef: any, old_photoSphereRef: any) => {
  const [is_gyroscope_on, change_gyroscope_state] = useState<boolean>(false);
  const config = typedSelector((state) => state.config);
  const { is_new_sphere } = config;

  useEffect(() => {
    const touch_move_gyro = document.getElementById("block_touch_move");
    if (!!touch_move_gyro) {
      if (is_gyroscope_on) {
        touch_move_gyro.style.display = "block";
        touch_move_gyro.addEventListener("touchstart", () => {
          gyro_check_func("stop");
        });
        touch_move_gyro.addEventListener("click", () => {
          gyro_check_func("stop");
        });
      } else {
        touch_move_gyro.style.display = "none";
      }
    } else {
      change_gyroscope_state(false);
    }
  }, [is_gyroscope_on]);

  const gyro_check_func = (start_gyro?: string) => {
    if (!new_photoSphereRef.current || !old_photoSphereRef.current) return;
    const old_panorama_gyroscope_plugin =
      //@ts-ignore
      old_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    const new_panorama_gyroscope_plugin =
      //@ts-ignore
      new_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    if (start_gyro === "stop") {
      old_panorama_gyroscope_plugin?.stop();
      new_panorama_gyroscope_plugin?.stop();
      change_gyroscope_state(false);
    }
    if (start_gyro === "toggle") {
      old_panorama_gyroscope_plugin?.toggle();
      new_panorama_gyroscope_plugin?.toggle();
      change_gyroscope_state((prev) => !prev);
    }
  };

  useEffect(() => {
    if (!new_photoSphereRef.current || !old_photoSphereRef.current) return;
    const old_panorama_gyroscope_plugin =
      //@ts-ignore
      old_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    const new_panorama_gyroscope_plugin =
      //@ts-ignore
      new_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    if (is_new_sphere) {
      const old_panorama_gyroscope_state = old_panorama_gyroscope_plugin?.state;
      if (old_panorama_gyroscope_state?.enabled) {
        new_panorama_gyroscope_plugin?.start();
        change_gyroscope_state(true);
      } else {
        new_panorama_gyroscope_plugin?.stop();
        change_gyroscope_state(false);
      }
    } else {
      const new_panorama_gyroscope_state = new_panorama_gyroscope_plugin?.state;
      if (new_panorama_gyroscope_state?.enabled) {
        old_panorama_gyroscope_plugin?.start();
        change_gyroscope_state(true);
      } else {
        old_panorama_gyroscope_plugin?.stop();
        change_gyroscope_state(false);
      }
    }
  }, [is_new_sphere, new_photoSphereRef, old_photoSphereRef]);

  return { gyro_check_func, is_gyroscope_on };
};

export default useGyroscope;
