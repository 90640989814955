import React, {FC, useEffect, useMemo} from "react";
import {set_old_spheres_status} from "../../../Redux/Actions";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../Redux/store";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {GyroscopePlugin, ReactPhotoSphereViewer, StereoPlugin} from "react-photo-sphere-viewer";
import resultStyle from "./sphereResult.module.scss";
import {Viewer} from "@photo-sphere-viewer/core";
import {SpheresPositionsI} from "../../../Types";

interface OldStyleViewerI {
    old_photoSphereRef: any,
    new_photoSphereRef: any,
    spheres_position: SpheresPositionsI
}


const OldStyleViewer: FC<OldStyleViewerI> = ({
                                                 old_photoSphereRef,
                                                 spheres_position,
                                                 new_photoSphereRef
                                             }) => {
    const dispatch = useDispatch<AppDispatch>();
    const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);
    const result = typedSelector((state) => state.common.result);



    useEffect(() => {
        if (!old_photoSphereRef.current) return;
        if (result.old_sphere_url) {
            dispatch(set_old_spheres_status());
            old_photoSphereRef?.current
                //@ts-ignore
                .setPanorama(result?.old_sphere_url)
                ?.then(() => {
                    //@ts-ignore
                    // dispatch(change_loading(false));
                })
                ?.then(() => {
                    dispatch(set_old_spheres_status(true));
                });
        }
    }, [result?.old_sphere_url]);

    const old_sphere = useMemo(() => {
        if (result?.old_sphere_url) {
            return (
                <ReactPhotoSphereViewer
                    ref={old_photoSphereRef}
                    container={""}
                    src={result.old_sphere_url}
                    height="100%"
                    width="100%"
                    navbar={false}
                    // touchmoveTwoFingers={true}
                    plugins={[
                        [
                            GyroscopePlugin,
                            {
                                touchmove: false,
                                absolutePosition: false,
                                moveMode: "smooth"
                            }
                        ],
                        StereoPlugin
                    ]}
                    containerClass={resultStyle.old_sphere}
                    defaultYaw={spheres_position.yaw}
                    defaultPitch={spheres_position.pitch}
                    defaultZoomLvl={0.1}
                    keyboard={'always'}
                    // keyboardActions={{
                    //   f: (viewer: Viewer): void => {
                    //     viewer.toggleFullscreen();
                    //   }
                    // }}
                    onZoomChange={({zoomLevel}) => {
                        if (!!new_photoSphereRef.current) {
                            //@ts-ignore
                            new_photoSphereRef.current.animate({zoom: zoomLevel})
                        }
                    }}
                    onPositionChange={(_lat: number, _lng: number, instance: Viewer) => {
                        const positions = instance.getPosition();
                        if (!!new_photoSphereRef.current) {
                            //@ts-ignore
                            new_photoSphereRef.current?.rotate({
                                pitch: positions.pitch,
                                yaw: positions.yaw
                            })
                        }
                    }}
                    onReady={() => {
                        dispatch(set_old_spheres_status(true));
                    }}
                />
            );
        }
    }, [
        result?.old_sphere_url,
        old_photoSphereRef
    ]);


    return <div
        className={
            !is_new_sphere
                ? `${resultStyle.old_sphere} ${resultStyle.active_viewer_old}`
                : resultStyle.old_sphere
        }
    >
        {old_sphere}
    </div>
}


export default OldStyleViewer