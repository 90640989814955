import React, { FC, useEffect, useRef } from "react";
import viewStyle from "./view.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import {
  change_extra_modal_state,
  set_360_page_config,
} from "../../../Redux/Actions";
import close_icon from "../../../Assets/Vectors/close.svg";
import sphere_360_image from "../../../Assets/Sphere/360-Photo-Gyro.jpg";
import toast from "react-hot-toast";
import {
  GyroscopePlugin,
  ReactPhotoSphereViewer,
  StereoPlugin,
} from "react-photo-sphere-viewer";
import resultStyle from "../../ResultStyles/PhotoSphereViewer/sphereResult.module.scss";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { isMobile } from "react-device-detect";

const View360: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const photoSphereRef = useRef<typeof ReactPhotoSphereViewer>();
  const can_show_360_page = typedSelector(
    (state) => state.config.can_show_360_page
  );

  const handleClose = () => {
    dispatch(change_extra_modal_state("Capture"));
    dispatch(set_360_page_config(false));
  };

  useEffect(() => {
    if (!photoSphereRef.current) return;
    const autorotate_plugin =
      //@ts-ignore
      photoSphereRef?.current.getPlugin("autorotate");
    //@ts-ignore
    autorotate_plugin.start();
    //@ts-ignore
    if (can_show_360_page) {
      const panorama_gyroscope_plugin =
        //@ts-ignore
        photoSphereRef.current?.getPlugin(GyroscopePlugin);
      if (isMobile) {
        panorama_gyroscope_plugin.start();
      } else {
        console.warn("Your device does not support gyroscope");
      }
    }
  }, [photoSphereRef.current, can_show_360_page]);

  return (
    <div
      className={
        can_show_360_page ? viewStyle.container_show : viewStyle.container_hide
      }
    >
      <div className={viewStyle.photosphere_viewer_block}>
        <div className={viewStyle.photosphere_viewer}>
          <ReactPhotoSphereViewer
            ref={photoSphereRef}
            src={sphere_360_image}
            height="100%"
            width="100%"
            container={""}
            navbar={false}
            containerClass={resultStyle.new_sphere}
            defaultYaw={4.96}
            defaultPitch={-0.26}
            defaultZoomLvl={0.1}
            keyboard={'always'}
            plugins={[
              [
                GyroscopePlugin,
                {
                  touchmove: true,
                  absolutePosition: true,
                  moveMode: "smooth",
                },
              ],
              StereoPlugin,
            ]}
          />
        </div>
        <div
          className={viewStyle.page_information_block}
          onClick={(e) => e.stopPropagation()}
        >
          <div onClick={() => handleClose()} className={viewStyle.back_button}>
            <img src={close_icon} alt="close" />
          </div>
          <div className={viewStyle.content}>
            <div className={viewStyle.text_box}>
              <p>CAPTURE</p>
              <p>YOUR</p>
              <p>SPACE IN</p>
              <p>FULL 360</p>
            </div>
            <div className={viewStyle.text_button_box}>
              <button
                onClick={() => {
                  toast("Coming soon");
                }}
                className={viewStyle.try_free}
              >
                <p>TRY FREE</p>
              </button>
              <p> Your session will resume after</p>
              <p>your 360 Capture.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View360;
