import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { AppDispatch } from "../Redux/store";
import {
  get_user_ip,
  upload_look,
  redirect_url,
  get_all_looks,
  upload_look_new,
  get_sphere_name,
  get_all_spheres,
  get_random_walls_tiles,
  get_next_random_walls_tiles_url,
  get_user_count,
  send_customer_form,
  update_user_count,
  update_email,
  upload_still_photo,
  change_wall_or_tile_new,
  text_me_form,
  text_user_form,
  rate_and_review,
} from "../Redux/Actions";

const ActionCreators = {
  send_customer_form,
  redirect_url,
  get_user_ip,
  upload_look_new,
  upload_look,
  get_all_looks,
  get_all_spheres,
  get_sphere_name,
  upload_still_photo,
  get_random_walls_tiles,
  get_next_random_walls_tiles_url,
  change_wall_or_tile_new,
  get_user_count,
  update_user_count,
  text_me_form,
  update_email,
  text_user_form,
  rate_and_review,
};

export const useAsyncActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  return bindActionCreators(ActionCreators, dispatch);
};
