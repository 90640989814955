import { FC } from "react";
import sphereBidStyle from "./sphereBids.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { change_modal, is_clicked } from "../../../Redux/Actions";

const SphereBids: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className={sphereBidStyle.bid_block}>
      <p>Want help from local contractors?</p>
      <div
        className={sphereBidStyle.tip_box}
        onClick={() => dispatch(change_modal("Bids"))}
      >
        Get Bids
      </div>
    </div>
  );
};

export default SphereBids;
