import React, { FC } from "react";
import resultStyle from "./sphereResult.module.scss";
import { isMobile } from "react-device-detect";
import view360_on from "../../../Assets/Vectors/view_360_on.svg";
import view360_off from "../../../Assets/Vectors/view_360_off.svg";
import useGyroscope from "./Hooks/useGyroscope";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import toast from "react-hot-toast";
import share_vector from "../../../Assets/Vectors/share.svg";
import {ServicePagesControl} from "../../../Services";
import {change_fullscreen_state, change_showed_sphere} from "../../../Redux/Actions";
import view_new from "../../../Assets/Vectors/open_eye.svg";
import view_old from "../../../Assets/Vectors/closed_eye.svg";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../Redux/store";
import full_screen_off from "../../../Assets/Vectors/full_screen_off.svg";
import full_screen_on from "../../../Assets/Vectors/full_screen_on.svg";
import useAutorotate from "./Hooks/useAutorotate";
// import {PhotoSphereRefT} from "../../../Types";


export const TouchMoveStop: FC = () => {

    return <div
        id="block_touch_move"
        className={resultStyle.block_touch_move}
    />
}

export const WriteSphereType: FC = () => {
    const is_random = typedSelector((state) => state.config.is_random);
    if (is_random) {
        return <div className={resultStyle.random_sphere}>
            <span>DEMO ROOM</span>
        </div>
    }
    return <></>
}


export const StopAutorotate: FC<{
    // new_photoSphereRef: PhotoSphereRefT
    new_photoSphereRef: any
}> = ({new_photoSphereRef}) => {
    useAutorotate(new_photoSphereRef.current);
    return <div id="autorotate" className={resultStyle.autorotate}/>
}

interface GyroscopeToggleI {
    // new_photoSphereRef: PhotoSphereRefT,
    // old_photoSphereRef: PhotoSphereRefT
    new_photoSphereRef: any,
    old_photoSphereRef: any
}

export const GyroscopeToggle: FC<GyroscopeToggleI> = ({new_photoSphereRef, old_photoSphereRef}) => {
    const is_full_screen_enabled = typedSelector((state) => state.config.is_full_screen_enabled);
    const {gyro_check_func, is_gyroscope_on} = useGyroscope(new_photoSphereRef, old_photoSphereRef);

    if (isMobile) {
        return <div
            onClick={(e) => {
                e.stopPropagation();
                gyro_check_func("toggle");
            }}
            className={
                is_full_screen_enabled
                    ? resultStyle.gyroscope_bottom
                    : resultStyle.gyroscope_top
            }
        >
            <img src={is_gyroscope_on ? view360_on : view360_off} alt="Gyroscope button"/>
        </div>
    }
    return <></>
}

export const ShareSphere: FC = () => {
    const is_full_screen_enabled = typedSelector((state) => state.config.is_full_screen_enabled);
    const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);
    const is_still_photo = typedSelector((state) => state.config.is_still_photo);
    const look_uploaded = typedSelector((state) => state.config.look_uploaded);
    const look = typedSelector((state) => state.common.look);
    const result = typedSelector((state) => state.common.result);
    const result_checker_state = typedSelector((state) => state.common.result_checker_state);


    const copied_url: string = ServicePagesControl.share_url_generator(
        look.url,
        is_still_photo ? result.new_still_photo_url : result.new_sphere_url,
        is_still_photo,
        look_uploaded,
        result_checker_state.called_id.ID || 0
    );

    if (is_new_sphere && !is_full_screen_enabled) {
        return <img
            className={resultStyle.share_button}
            onClick={() => {
                if (navigator?.share) {
                    navigator
                        .share({
                            title: "Check out this look in my space!",
                            url: copied_url
                        })
                        .then(() => console.log("Success"))
                        .catch(() => console.log("Canceled"));
                } else {
                    console.warn("Your browser doesn't have native share");
                }
                if (navigator?.clipboard && navigator?.clipboard?.writeText) {
                    navigator.clipboard.writeText(copied_url).then(() => {
                        toast("Link copied to clipboard");
                    });
                } else {
                    console.warn("Please update your browser!!");
                }
            }}
            src={share_vector}
            alt="share"
        />
    }


    return <></>
}

export const ShowedSphere: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);


    if (is_new_sphere) {
        return (
            <>
                <div
                    onClick={() => dispatch(change_showed_sphere())}
                    className={resultStyle.new_old_clickable_area}
                />
                <img
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(change_showed_sphere());
                    }}
                    className={resultStyle.view_old}
                    src={view_new}
                    alt="view old sphere"
                />
            </>
        );
    }

    return (
        <>
            <div
                onClick={() => dispatch(change_showed_sphere())}
                className={resultStyle.new_old_clickable_area}
            />
            <img
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(change_showed_sphere());
                }}
                className={resultStyle.view_new}
                src={view_old}
                alt="view new sphere"
            />
        </>
    );
}

export const FullScreenButton: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const is_fullscreen_enabled = typedSelector((state) => state.config.is_full_screen_enabled);
    const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);
    if (sphere_statuses.new_sphere) {
        if (is_fullscreen_enabled) {
            return (
                <>
                    <div
                        onClick={() => {
                            dispatch(change_fullscreen_state())
                        }}
                        className={resultStyle.full_screen_clickable_area}
                    />
                    <img
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(change_fullscreen_state())
                        }}
                        className={resultStyle.full_screen_off}
                        src={full_screen_off}
                        alt="full screen off"
                    />
                </>
            );
        }
        return (
            <>
                <div
                    onClick={() => {
                        dispatch(change_fullscreen_state())
                    }}
                    className={resultStyle.full_screen_clickable_area}
                />
                <img
                    onClick={() => {
                        dispatch(change_fullscreen_state())
                    }}
                    className={resultStyle.full_screen_on}
                    src={full_screen_on}
                    alt="full screen on"
                />
            </>
        );
    }
    return <></>
}

export const ViewerNavbar: FC = () => {
    const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);

    return <>
        <div className={resultStyle.back_fon}/>
        <div
            style={{display: !sphere_statuses.new_sphere ? "none" : "block"}}
            className={resultStyle.navbar}
        >
            <ShareSphere/>
            <ShowedSphere/>
        </div>
    </>
}


