import { useEffect } from "react";
import { change_marker } from "../../../../Common/Config";
import { green_dots_and_tag_markers_functional } from "../photosphereViewerConfig";
import { typedSelector } from "../../../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";


const useViewerMarkers = (new_photoSphereRef: any, old_photoSphereRef: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const look = typedSelector((state) => state.common.look);
  const logos_positions  = typedSelector((state) => state.common.logos_positions);
  const material_type = typedSelector((state) => state.config.material_type);
  const is_fullscreen_enabled = typedSelector((state) => state.config.is_full_screen_enabled);

  useEffect(() => {
    if (!new_photoSphereRef.current || !old_photoSphereRef.current) return;
    //@ts-ignore
    const markers = new_photoSphereRef?.current?.getPlugin("markers");
    if (is_fullscreen_enabled) {
      if (markers?.markers["wall-marker"]) {
        markers?.updateMarker({
          id: "wall-marker",
          html: "<div></div>",
        });
      }
      if (markers?.markers["tile-marker"]) {
        markers?.updateMarker({
          id: "tile-marker",
          html: "<div></div>",
        });
      }
    }
    if (!is_fullscreen_enabled) {
      if (
        markers?.markers["tile-marker"] &&
        markers?.markers["wall-marker"] &&
        logos_positions.walls_marker
      ) {
        markers?.updateMarker(
          change_marker("wall", look?.wallColor, logos_positions, material_type)
        );
        markers?.updateMarker(
          change_marker("tile", look?.floorTile, logos_positions, material_type)
        );
      }
    }
    green_dots_and_tag_markers_functional(dispatch, look, material_type);
  }, [
    is_fullscreen_enabled,
    new_photoSphereRef,
    old_photoSphereRef,
    material_type,
  ]);
};

export default useViewerMarkers;
