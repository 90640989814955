import React, {FC, useEffect, useRef} from "react";
import resultStyle from "./sphereResult.module.scss";
import {ReactPhotoSphereViewer} from "react-photo-sphere-viewer";
import { use_two_fingers } from "./photosphereViewerConfig";
import ViewerLoading from "./MiniLoading/photosphereLoading";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {SpheresPositionsI} from "../../../Types";
import {handler_hardcoded_sphere_logo_location} from "../../../Common/Config";
import useDeviceHeight from "../../../Hooks/useDeviceHeight";
import OldStyleViewer from "./oldStyleViewer";
import NewStyleViewer from "./newStyleViewer";
import {
    FullScreenButton,
    GyroscopeToggle,
    StopAutorotate,
    TouchMoveStop, ViewerNavbar, WriteSphereType
} from "./components";

const PhotoSphereViewer: FC = () => {
    const config = typedSelector((state) => state.config);
    const sphere = typedSelector((state) => state.common.sphere);

    const {
        sphere_statuses,
        is_full_screen_enabled
    } = config;


    const new_photoSphereRef = useRef<typeof ReactPhotoSphereViewer>(),
        old_photoSphereRef = useRef<typeof ReactPhotoSphereViewer>();
    const spheres_position = useRef<SpheresPositionsI>(
        handler_hardcoded_sphere_logo_location(sphere.endpoint)
    );

    const device_height = useDeviceHeight();


    // USE TWO FINGERS
    useEffect(() => {
        if (!new_photoSphereRef.current && !old_photoSphereRef.current) return;
        use_two_fingers();
    }, [new_photoSphereRef.current, old_photoSphereRef.current]);


    return (
        <div
            style={is_full_screen_enabled ? {height: device_height} : {}}
            className={
                is_full_screen_enabled ? resultStyle.viewer_2 : resultStyle.viewer
            }
        >
            <WriteSphereType />
            <FullScreenButton />
            <ViewerNavbar />
            <GyroscopeToggle
                new_photoSphereRef={new_photoSphereRef}
                old_photoSphereRef={old_photoSphereRef}
            />
            <TouchMoveStop  />

            <StopAutorotate
                new_photoSphereRef={new_photoSphereRef}
            />
            {!sphere_statuses.new_sphere && <ViewerLoading sphere_type="new"/>}
            <NewStyleViewer
                old_photoSphereRef={old_photoSphereRef}
                new_photoSphereRef={new_photoSphereRef}
                spheres_position={spheres_position.current}
            />
            <OldStyleViewer
                old_photoSphereRef={old_photoSphereRef}
                new_photoSphereRef={new_photoSphereRef}
                spheres_position={spheres_position.current}
            />
        </div>
    );
};

export default PhotoSphereViewer;
