import { DataControl } from "./serviceDataControl";
import { ApiControl } from "./serviceApi";
import { PagesControl } from "./servicePages";
import { ConfigControl } from "./serviceConfig";

const ServiceDataControl = new DataControl(),
  ServiceApi = new ApiControl(),
  ServicePagesControl = new PagesControl(),
  ServiceConfig = new ConfigControl();

export { ServiceApi, ServiceConfig, ServiceDataControl, ServicePagesControl };
