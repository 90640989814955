import { ChangeEvent, FC, useState } from "react";
import congratulationStyle from "./congratulation.module.scss";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { useAsyncActions } from "../../../Hooks/useActions";
import { EmailStateI } from "../../../Types";
import { isValidate } from "../../../Common/Config";
import { toast } from "react-hot-toast";
import { useCookies } from "react-cookie";

const Congratulation: FC = () => {
  const { update_user_count } = useAsyncActions();
  const count = typedSelector((state) => state.config.count);
  const [name, setName] = useState<string>("");
  const [, setCookie] = useCookies(["email"]);
  const [emailState, setEmailState] = useState<EmailStateI>({
    email: "",
    message: "",
    errorMessage: "",
  });

  const handleNumber = () => {
    if (isValidate("email", emailState.email) && !!name) {
      setEmailState({
        ...emailState,
        email: emailState.email,
        errorMessage: "",
      });
      update_user_count(
        {
          name: name,
          email: emailState.email,
        },
        setCookie
      );
    } else {
      toast.error(name ? "Check your email" : "Name is required");
      setEmailState({
        ...emailState,
        email: "",
        errorMessage: "Check your email",
      });
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailState({
      ...emailState,
      email: e.target.value,
      errorMessage: "",
    });
  };

  return (
    <div className={congratulationStyle.component}>
      <div className={congratulationStyle.box}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={congratulationStyle.content}
        >
          <p className={congratulationStyle.congrate_text}>Congratulations!</p>
          <p className={congratulationStyle.user_count}>User {count + 1}</p>
          <div className={congratulationStyle.text}>
            <p>Enter your name and</p>
            <p>email to claim your free</p>
            <p>membership!</p>
          </div>
          <input
            type="text"
            name="nameInput"
            placeholder="*Name"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
          <input
            type="text"
            name="emailInput"
            placeholder="*Email"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeEmail(e)
            }
          />
          <div className={congratulationStyle.button} onClick={handleNumber}>
            Join
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
