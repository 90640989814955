import React, { FC } from "react";
import QrStyle from "./qrStyle.module.scss";
import logo from "../../Assets/Images/logo.svg";
import imgWeb from "../../Assets/Web/web_image.jpg";
import imgBottom from "../../Assets/Web/web_footer.jpg";
import qr from "../../Assets/Web/qr.png";
import Footer from "../Footer/footer";
import upIcon from "../../Assets/Icons/up.png";

const QR: FC = () => {
  return (
    <div className={QrStyle.qr_wrapper}>
      <div className={QrStyle.component}>
        <div className={QrStyle.qr_box}>
          <img src={qr} alt="bgd qr" className={QrStyle.qr} />
          <p className={QrStyle.bottom_text}>Scan to experience on mobile!</p>
        </div>
        <div className={QrStyle.container}>
          <div className={QrStyle.header}>
            <img src={logo} alt="logo" />
          </div>
          <div className={QrStyle.main_img_box}>
            <img src={imgWeb} alt="web img" className={QrStyle.main_img} />
          </div>
        </div>
        <div className={QrStyle.bottom_img_box}>
          <img
            src={imgBottom}
            alt="web img bottom"
            className={QrStyle.bottom_img}
          />
          <div className={QrStyle.footer_content}>
            <Footer classname={QrStyle.footer} />
            <p className={QrStyle.text}>
              Copyright DoSpace, Inc. 2023 | (402) 534-1402 | v1.0
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QR;
