import React, { FC } from "react";
import sphereMaterialStyle from "./sphereMaterial.module.scss";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { change_material_type } from "../../../Redux/Actions";

const SphereMaterial: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const material_type = typedSelector((state) => state.config.material_type);

  return (
    <div className={sphereMaterialStyle.sphere_block}>
      <div className={sphereMaterialStyle.sphere_buttons}>
        <div
          className={
            material_type === "wall"
              ? `${sphereMaterialStyle.walls_button} ${sphereMaterialStyle.active}`
              : sphereMaterialStyle.walls_button
          }
          onClick={() => dispatch(change_material_type("wall"))}
        >
          WALLS
        </div>
        <div
          className={
            material_type === "tile"
              ? `${sphereMaterialStyle.floors_button} ${sphereMaterialStyle.active}`
              : sphereMaterialStyle.floors_button
          }
          onClick={() => dispatch(change_material_type("tile"))}
        >
          FLOORS
        </div>
        <div
          className={
            material_type === "more"
              ? `${sphereMaterialStyle.more_button} ${sphereMaterialStyle.active}`
              : sphereMaterialStyle.more_button
          }
          onClick={() => dispatch(change_material_type("more"))}
        >
          MORE...
        </div>
      </div>
    </div>
  );
};

export default SphereMaterial;
