import React, { ChangeEvent, FC, useState } from "react";
import emailStyle from "./email.module.scss";
import { isValidate } from "../../../Common/Config";
import { EmailStateI } from "../../../Types";
import { upload_modal_open } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useAsyncActions } from "../../../Hooks/useActions";
import { typedSelector } from "../../../Hooks/useTypedSelector";

const Email: FC<{ is_still: boolean; message?: string }> = ({
  is_still,
  message,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [, setCookie] = useCookies(["email"]);
  const {
    update_email,
    text_user_form,
    upload_look_new,
    upload_still_photo,
    change_wall_or_tile_new,
  } = useAsyncActions();
  const config = typedSelector((state) => state.config);
  const common = typedSelector((state) => state.common);
  const [cookie] = useCookies(["email"]);

  const { material_type, modal_type } = config;

  const [emailState, setEmailState] = useState<EmailStateI>({
    email: "",
    message: message || "",
    errorMessage: "",
  });

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailState({
      ...emailState,
      email: e.target.value,
    });
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (!is_still) {
        upload_look_new(e.target.files[0], common, config);
      }
      if (is_still) {
        upload_still_photo(
          e.target.files[0],
          change_wall_or_tile_new,
          common,
          config
        );
      }
    }
  };

  const handleEmailValidation = () => {
    if (!isValidate("email", emailState.email)) {
      toast.error(emailState.errorMessage || "Email is required");
      setEmailState({
        ...emailState,
        email: "",
        errorMessage: "Check your email",
      });
    }
  };

  const submit = () => {
    if (isValidate("email", emailState.email)) {
      dispatch(upload_modal_open(false));
      material_type === "more"
        ? text_user_form(
            {
              email: emailState.email,
              message: emailState.message,
              errorMessage: "",
            },
            setCookie
          )
        : update_email(emailState, setCookie);
    } else {
      toast.error(emailState.errorMessage || "Email is required");
      setEmailState({
        ...emailState,
        email: "",
        errorMessage: "Check your email",
      });
    }
  };

  return (
    <div
      className={emailStyle.component}
      style={
        modal_type === "Inspiration" ? { marginTop: "60px" } : { marginTop: 0 }
      }
    >
      <div onClick={(e) => e.stopPropagation()} className={emailStyle.box}>
        <div className={emailStyle.content}>
          <p>Please enter your email to</p>
          <p>
            {material_type !== "more"
              ? `add your own design inspiration`
              : `submit your message`}
          </p>
          <input
            type="email"
            name="emailInput"
            placeholder="*Email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleEmail(e)}
          />

          <div className={emailStyle.button} onClick={handleEmailValidation}>
            {material_type !== "more" ? (
              <label htmlFor="upload_look">
                Submit
                {isValidate("email", emailState.email) ? (
                  <input
                    id="upload_look"
                    type="file"
                    style={{ display: "none" }}
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangeEmail(e)
                    }
                    onClick={() => {
                      !cookie.email && submit();
                    }}
                  />
                ) : (
                  <></>
                )}
              </label>
            ) : (
              <div onClick={submit}>Submit</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
