import { SET_SESSION_ID } from "./commonAction";
import {
  PayloadType,
  RConfigStateI,
  WallTileLogosPositionsI,
} from "../../../Types";

export const LOADING: string = "LOADING";
export const SET_LOOK_TYPE: string = "SET LOOK TYPE";
export const SET_SPHERE_TYPE: string = "SET SPHERE TYPE";
export const SET_STILL_PHOTO_TYPE: string = "SET STILL PHOTO TYPE";
export const CHANGE_SHOWED_SPHERE: string = "CHANGE SHOWED SPHERE";
export const SET_CONFIG_REDUCER_STATE: string = "SET_CONFIG_REDUCER_STATE";
export const GET_QUOTES_MODAL: string = "GET QUOTES MODAL";
export const OPEN_MODAL: string = "QUOTES MODAL";
export const LOAD_TIME: string = "SET LOAD TIME";
export const RESET_CONFIG: string = "RESET CONFIG";
export const SET_WALL_TILE_LOGOS_POSITIONS: string =
  "SET_WALL_TILE_LOGOS_POSITIONS";
export const SET_RANDOM: string = "SET RANDOM";
export const IS_SECOND_PAGE_START: string = "IS_SECOND_PAGE_START";
export const THANKS_TEXT: string = "THANKS TEXT";
export const OPEN_UPLOAD: string = "OPEN UPLOAD";
export const CLOSE_MODAL: string = "CLOSE MODAL";
export const CHANGE_MATERIAL_TYPE: string = "CHANGE MATERIAL TYPE";
export const SET_COUNT: string = "SET COUNT";
export const SET_NAME: string = "SET NAME";
export const IS_REMINDER: string = "IS REMINDER";
export const EXTRA_MODAL: string = "EXTRA MODAL";
export const MORE_TEXT: string = "MORE TEXT";
export const CURRENT_PROCESS_TYPE: string = "CURRENT PROCESS TYPE";
export const IS_DESKTOP: string = "IS DESKTOP";
export const IS_CLICKED: string = "IS CLICKED";
export const CAN_SHOW_360_PAGE: string = "CAN SHOW 360 PAGE";

export const FULL_SCREEN_STATE: string = "FULL SCREEN STATE";

export const CHANGE_FULL_SCREEN_STATE: string = "CHANGE FULL SCREEN STATE";

export const set_full_screen_state = (
  payload = false
): PayloadType<boolean> => {
  return {
    type: FULL_SCREEN_STATE,
    payload,
  };
};

export const change_fullscreen_state = (): { type: string } => {
  return {
    type: CHANGE_FULL_SCREEN_STATE,
  };
};

export const set_360_page_config = (payload: boolean): PayloadType<boolean> => {
  return {
    type: CAN_SHOW_360_PAGE,
    payload,
  };
};

export const set_current_process_type = (
  payload: string
): PayloadType<string> => {
  return {
    type: CURRENT_PROCESS_TYPE,
    payload,
  };
};

export const set_desktop = (payload: boolean): PayloadType<boolean> => {
  return {
    type: IS_DESKTOP,
    payload,
  };
};

export const change_extra_modal_state = (payload = ""): PayloadType<string> => {
  return {
    type: EXTRA_MODAL,
    payload,
  };
};

export const change_material_type = (payload: string): PayloadType<string> => {
  return {
    type: CHANGE_MATERIAL_TYPE,
    payload,
  };
};

export const is_second_page_start = (
  payload: boolean
): PayloadType<boolean> => {
  return {
    type: IS_SECOND_PAGE_START,
    payload,
  };
};

export const reset_config = (): PayloadType<null> => {
  return {
    type: RESET_CONFIG,
  };
};

export const is_clicked = (payload = false): PayloadType<boolean> => {
  return {
    type: IS_CLICKED,
    payload,
  };
};

export const set_sessionID = (payload: string): PayloadType<string> => {
  return {
    type: SET_SESSION_ID,
    payload,
  };
};

export const set_config_reducer_state = (
  payload: RConfigStateI
): PayloadType<RConfigStateI> => {
  return {
    type: SET_CONFIG_REDUCER_STATE,
    payload,
  };
};

export const set_still_photo_type = (payload = false): PayloadType<boolean> => {
  return {
    type: SET_STILL_PHOTO_TYPE,
    payload,
  };
};

export const set_look_type = (payload = false): PayloadType<boolean> => {
  return {
    type: SET_LOOK_TYPE,
    payload,
  };
};

export const set_count = (payload = 0): PayloadType<number> => {
  return {
    type: SET_COUNT,
    payload,
  };
};

export const set_user = (payload: string): PayloadType<string> => {
  return {
    type: SET_NAME,
    payload,
  };
};

export const set_more_text = (payload: string): PayloadType<string> => {
  return {
    type: MORE_TEXT,
    payload,
  };
};

export const set_sphere_type = (payload = false): PayloadType<boolean> => {
  return {
    type: SET_SPHERE_TYPE,
    payload,
  };
};

export const change_loading = (payload: boolean): PayloadType<boolean> => {
  return {
    type: LOADING,
    payload,
  };
};

export const change_reminder = (payload: boolean): PayloadType<boolean> => {
  return {
    type: IS_REMINDER,
    payload,
  };
};

export const set_random = (payload: boolean): PayloadType<boolean> => {
  return {
    type: SET_RANDOM,
    payload,
  };
};

export const change_showed_sphere = (
  payload?: boolean
): PayloadType<boolean> => {
  return {
    type: CHANGE_SHOWED_SPHERE,
    payload,
  };
};

export const upload_modal_open = (payload?: boolean): PayloadType<boolean> => {
  return {
    type: OPEN_UPLOAD,
    payload,
  };
};

export const change_modal = (payload = ""): PayloadType<string> => {
  return {
    type: OPEN_MODAL,
    payload,
  };
};

export const close_modal = (payload = ""): PayloadType<string> => {
  return {
    type: CLOSE_MODAL,
    payload,
  };
};

export const set_load_time = (payload: number): PayloadType<number> => {
  return {
    type: LOAD_TIME,
    payload,
  };
};

export const text_thanks = (payload = ""): PayloadType<string> => {
  return {
    type: THANKS_TEXT,
    payload,
  };
};

export const set_wall_tile_logos_positions = (
  payload: WallTileLogosPositionsI
): PayloadType<WallTileLogosPositionsI> => {
  return {
    type: SET_WALL_TILE_LOGOS_POSITIONS,
    payload,
  };
};
