import React, {FC} from "react";
import stillPhotoStyle from "./stillPhoto.module.scss";
import StillMarker from "../../../Common/Markers/marker";
import ViewerLoading from "../../ResultStyles/PhotoSphereViewer/MiniLoading/photosphereLoading";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {ChangeShowedSphere, NewLookStyle, OldLookStyle, ShareButton} from "./components";
import UseImageProportion from "./useImageProportion";


const StillPhoto: FC = () => {
    const container_min_height = UseImageProportion()
    const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);

    return <div className={stillPhotoStyle.still_photos}>
        <div
            style={!sphere_statuses.new_sphere ? {minHeight: container_min_height} : {}}
            className={stillPhotoStyle.new_still_photo_container}
        >
            <StillMarker/>
            {!sphere_statuses.new_sphere && (
                <div className={stillPhotoStyle.loading_container_still_photo}>
                    <ViewerLoading sphere_type="new"/>
                </div>
            )}
            <NewLookStyle/>
            <OldLookStyle/>

            <div className={stillPhotoStyle.still_photo_navbar}>
                <ShareButton/>
                <ChangeShowedSphere/>
            </div>
        </div>
    </div>;
};

export default StillPhoto;