import { RoutesI, SpheresPositionsI } from "../../Types";
import { ServiceApi } from "../../Services";

export const routes: RoutesI = {
  auth: "/auth",
  home: "/",
  look_space: "/look-in-your-space-beta",
  demo_new_look: "/demo-new-look-beta",
  shared_third_page: "/shared/:look_type/:look_info/:sphere_type/:sphere_info/:sphere_id"
};

export const verifySphere = () => {
  setTimeout(async () => {
    await fetch(
      `${ServiceApi.get_server_url()}/getSphereGenerationStatus?sessionID=${sessionStorage.getItem(
        "sessionID"
      )}`
    )
      .then((response) => response.text())
      .then((data) => {
        if (!data) {
          verifySphere();
        } else {
          // it's a bad way
          if (data[0] === "<") {
            verifySphere();
          } else {
            //
          }
        }
      })
      .catch(() => {
        verifySphere();
        // loading.innerHTML = JSON.stringify(e);
      });
  }, 7000);
};


export const handler_hardcoded_sphere_logo_location = (
  endpoint: string
): SpheresPositionsI => {
  if (endpoint === "demo_sample_1") {
    return {
      pitch: 0,
      yaw: 2.5
    };
  }
  if (endpoint === "demo_sample_2") {
    return {
      pitch: -0.4,
      yaw: 3
    };
  }
  if (endpoint === "demo_sample_3") {
    return {
      pitch: -0.4,
      yaw: 3
    };
  }
  if (endpoint === "demo_sample_4") {
    return {
      pitch: -0.4,
      yaw: 1.4
    };
  }
  return {
    pitch: 12,
    yaw: 12,
  };
};
