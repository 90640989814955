import React, { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { ServiceDataControl, ServicePagesControl } from "../../Services";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  set_common_reducer_state,
  set_config_reducer_state,
  set_full_screen_state,
  set_look,
  shared_page,
} from "../../Redux/Actions";
import { routes } from "../../Common/Config";

const SharedPage = () => {
  const url_params = useParams();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    ServicePagesControl.shared_third_page({
      look_info: url_params.look_info || "",
      sphere_info: url_params.sphere_info || "",
      look_type: url_params.look_type || "",
      sphere_type: url_params.sphere_type || "",
      sphere_id: url_params.sphere_id || "",
    })
      .then((data) => {
        if (!data.error_message && !!data?.app_state) {
          dispatch(set_common_reducer_state(data?.app_state?.common));
          dispatch(
            set_config_reducer_state({
              ...data?.app_state.config,
              is_random: !data?.app_state?.config.is_still_photo,
            })
          );
          if (
            data?.app_state?.common?.look?.wallColor.id !==
            JSON.parse(String(sessionStorage.getItem("default_look")))
              ?.wallColor?.id
          ) {
            dispatch(
              set_look({
                ...data?.app_state?.common?.look,
                url: "",
              })
            );
            ServiceDataControl.set_session_storage_item<string>(
              "saved_look_url",
              data.app_state.common.look.url
            );
            ServiceDataControl.save_configs(data.app_state.config, {
              ...data.app_state.common,
              look: {
                ...data.app_state.common.look,
                url: "",
              },
            });
          }
          if (
            data?.app_state?.common?.look?.floorTile.id !==
            JSON.parse(String(sessionStorage.getItem("default_look")))
              ?.floorTile?.id
          ) {
            dispatch(
              set_look({
                ...data?.app_state?.common?.look,
                url: "",
              })
            );
            ServiceDataControl.set_session_storage_item<string>(
              "saved_look_url",
              data.app_state.common.look.url
            );
            ServiceDataControl.save_configs(data.app_state.config, {
              ...data.app_state.common,
              look: {
                ...data.app_state.common.look,
                url: "",
              },
            });
          }
          ServiceDataControl.set_session_storage_item(
            "sphere_id",
            url_params.sphere_id
          );
        }
      })
      .then(() => {
        dispatch(shared_page(true));
        dispatch(change_loading(false));
        dispatch(change_modal());
        dispatch(change_extra_modal_state());
        dispatch(set_full_screen_state());
        navigate(routes.demo_new_look);
      });
  }, []);

  return <></>;
};

export default SharedPage;
