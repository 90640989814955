import {
  ChangeWallORTileI,
  LookDataI,
  RCommonStateI,
  RConfigStateI,
  ResultDataI,
  SphereDataI,
} from "../../Types";
import { AppDispatch } from "../../Redux/store";
import { Look_controller, Sphere_controller } from "../../Controllers";
import {
  change_modal,
  clear_loading_time,
  set_current_process_type,
  set_new_spheres_status,
} from "../../Redux/Actions";
import { clear_inspiration_image_base_64 } from "../../Redux/Actions/CommonActions/assetsAction";
import { ServiceDataControl } from "../../Services";

export const hard_coded_sphere_first_open: SphereDataI = {
  id: 1,
  name: "demo_sample_1",
  sphereUrl: "https://public.stagyc.com/hardcoded_spheres/demo_sample_1.jpg",
  thumbnailUrl:
    "https://public.stagyc.com/hardcoded_spheres/demo_sample_1_thumbnail.jpg",
};

export const choose_hard_coded_look = (
  look: LookDataI,
  dispatch: AppDispatch,
  result: ResultDataI,
  common: RCommonStateI,
  config: RConfigStateI,
  selected_look_id: number,
  change_wall_or_tile_new: (payload: ChangeWallORTileI) => void
): void => {
  if (selected_look_id === look.id) {
    dispatch(change_modal());
    return;
  }
  ServiceDataControl.remove_session_storage_item("saved_look_url");
  dispatch(clear_inspiration_image_base_64());
  dispatch(set_current_process_type("hardcoded_look"));
  // dispatch(change_loading(true));
  dispatch(clear_loading_time());
  dispatch(set_new_spheres_status(false));
  Look_controller.select_look(look, dispatch);
  dispatch(change_modal());

  if (result.old_sphere_url || result.old_still_photo_url) {
    change_wall_or_tile_new({
      wall: look?.wallColor,
      tile: look?.floorTile,
      sphere_endpoint: !config.is_still_photo
        ? result.sphere_endpoint
        : result.still_photo_endpoint,
      process_type: !config.is_still_photo ? "hardcodedsphere" : "stillphoto",
      interactionSource: "hardcodedLook",
      common,
      config,
    });
  } else {
    Sphere_controller.handler_sphere(
      hard_coded_sphere_first_open,
      dispatch,
      look,
      config.look_uploaded
    );
    change_wall_or_tile_new({
      wall: look?.wallColor,
      tile: look?.floorTile,
      sphere_endpoint: "demo_sample_1",
      process_type: "hardcodedsphere",
      interactionSource: "hardcodedLook",
      common,
      config,
    });
  }
};
